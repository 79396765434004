import api from 'config/axiosConfig';

export interface UrlMetadata {
  title?: string;
  description?: string;
  image?: any;
  siteName?: string;
}

export const fetchUrlMetadata = async (url: string): Promise<UrlMetadata | null> => {
  try {
    const response = await api.get<UrlMetadata>('/api/utilities/url/metadata', {
      params: { url },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching metadata:', error);
    return null;
  }
};