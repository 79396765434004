import xss from 'xss';

const xssOptions: XSS.IFilterXSSOptions = {
  whiteList: {
    a: ['href', 'title', 'target'],
    b: [],
    i: [],
    u: [],
    s: [],
    p: ['class', 'max-w-fit', 'max-w-[100%]'],
    br: [],
    ul: [],
    ol: [],
    li: [],
    strong: [],
    em: [],
    blockquote: [],
    code: [],
    pre: [],
    h1: [],
    h2: [],
    h3: [],
    h4: [],
    h5: [],
    h6: [],
    img: ['src', 'alt', 'title', 'width', 'height', 'class', 'max-w-fit', 'max-w-[100%]'],
    span: ['data-id', 'data-label', 'data-type', 'class'],
    div: [],
    iframe: ['src', 'title', 'width', 'inline', 'controls', 'height', 'origin', 'allowfullscreen', 'autoplay'],
  },
  stripIgnoreTag: true,
  stripIgnoreTagBody: ['script'],
};

export const sanitize = (html: string) => {
  return xss(html, xssOptions);
};