import React, { useEffect } from 'react'
import { useUser } from 'util/UserContext'
import Drawer from '@mui/material/Drawer'
import { ReactComponent as IconContentPolicy } from 'assets/icon-content-policy.svg'
import { ReactComponent as IconUserAgreement } from 'assets/icon-user-agreement.svg'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { 
    Home,
    MessageSquarePlus,
    ShieldCheck,
    Flag,
    Bug,
    Scale,
    HeartHandshake,
    BriefcaseBusiness,
    MessageCircleQuestion
  } from 'lucide-react'

  const CustomDrawer = styled(Drawer)(({ theme }) => ({
    top: theme.mixins.toolbar.minHeight,
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    '& .MuiDrawer-paper': {
        top: theme.mixins.toolbar.minHeight,
        height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
        width: '260px',
        zIndex: theme.zIndex.drawer,
        borderTop: '0.1rem solid #c8c8c8',
        backgroundColor: 'var(--mui-palette-background-paper)',
        '[class*="dark"] &': {
            backgroundColor: 'var(--mui-palette-background-default)',
            borderColor: 'var(--mui-palette-divider)',
        }
    },
}));


const SideNavBar = ({ isOpen, toggleDrawer, onClick }: { isOpen: boolean, toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void, onClick: () => void }) => {
    const { currentUser } = useUser()
    const navigate = useNavigate()

    // Handle cleanup of body styles when drawer closes
    useEffect(() => {
        if (!isOpen) {
            document.body.style.overflow = ''
            document.body.style.paddingRight = ''
        }
    }, [isOpen])

    // Handle navigation and drawer cleanup
    const handleNavigation = (to: string) => {
        navigate(to)
        toggleDrawer(false)({} as React.MouseEvent) // Close drawer
        document.body.style.overflow = '' // Reset body scroll
        document.body.style.paddingRight = '' // Reset body padding
        onClick() // Execute any additional onClick handlers
    }

    return (
        <div>
            {/* Persistent sidebar */}
            <CustomDrawer
                variant="permanent"
                className="hidden xl:block px-md"
                classes={{
                    paper: "bg-white dark:bg-eoe-dark-bg transition-colors duration-200 px-2"
                }}
            >
                {/* Motto chip replacement */}
                <div className="my-2 px-1 py-1 bg-[#005286] w-full font-semibold text-white text-xs rounded-full text-center">
                    United we stand, prioritized they fall
                </div>

                {/* Navigation list replacement */}
                <nav className="flex flex-col text-sm font-light text-eoe-gray dark:text-eoe-dark-text-2">
                    {/* Home link */}
                    <div className="flex flex-col px-2 ">
                        <button 
                            onClick={() => handleNavigation('/')}
                            className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                        >
                            <Home size={20} />
                            <span>Home</span>
                        </button>

                        {/* Feedback link */}
                        <button 
                            onClick={() => handleNavigation('/feedback')}
                            className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                        >
                            <MessageSquarePlus size={20} />
                            <span>Feedback</span>
                        </button>
                    </div>

                    {/* Divider */}
                    <div className="mx-2 my-2 list-divider" />

                    {/* Admin section */}
                    {currentUser && currentUser.attributes.is_admin && (
                        <div className="flex flex-col px-2 ">
                            <span className="px-2 py-2 text-gray-500 dark:text-gray-400 font-light">
                                ADMIN
                            </span>
                            
                            <button 
                                onClick={() => handleNavigation('/admin')}
                                className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                            >
                                <ShieldCheck size={20} />
                                <span>Admin Home</span>
                            </button>

                            <button 
                                onClick={() => handleNavigation('/admin/reports')}
                                className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                            >
                                <Flag size={20} />
                                <span>Reported Items</span>
                            </button>

                            <button 
                                onClick={() => handleNavigation('/admin/feedback')}
                                className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                            >
                                <Bug size={20} />
                                <span>User Feedback</span>
                            </button>
                            <div className="mx-2 my-2 list-divider" />
                        </div>
                    )}

                    {/* Policies section */}
                    <div className="flex flex-col px-2 ">
                        <span className="px-2 py-2 text-gray-500 dark:text-gray-400 font-light">
                            RESOURCES
                        </span>
                        <a href="https://societea.io" className="menu-item-alt">
                            <button
                                className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                            >
                                <HeartHandshake size={20} />
                                <span>Powered by Societea</span>
                            </button>
                        </a>

                        <a href="https://societea.io/careers" className="menu-item-alt">
                            <button 
                                className="flex items-center gap-3 px-4 py-2"
                            >
                                <BriefcaseBusiness size={20} />
                                <span>Help Build Societea</span>
                            </button>
                        </a>            

                        <a href="mailto:support@societea.io" className="menu-item-alt">
                            <button 
                                className="flex items-center gap-3 px-4 py-2"
                            >
                                <MessageCircleQuestion size={20} />
                                <span>Help</span>
                            </button>
                        </a>
                    </div>
                    
                    <div className="mx-2 my-2 list-divider" />

                    <div className="flex flex-col px-2 ">
                        <span className="px-2 py-2 text-gray-500 dark:text-gray-400 font-light">
                            PRIVACY & SAFETY
                        </span>
                        <a href="https://societea.io/policies/user-agreement" className="menu-item-alt">
                            <button
                                className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                            >
                                <IconContentPolicy className="size-5" />
                                <span>Content Policy</span>
                            </button>
                        </a>

                        <button 
                            onClick={() => handleNavigation('/policies/privacy-policy')}
                            className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                        >
                            <Scale size={20} />
                            <span>Privacy Policy</span>
                        </button>

                        <a href="https://societea.io/policies/user-agreement" className="menu-item-alt">
                            <button 
                                className="flex items-center gap-3 px-4 py-2"
                            >
                                <IconUserAgreement className="size-5" />
                                <span>User Agreement</span>
                            </button>
                        </a>
                    </div>
                </nav>
                    
                {/* Footer replacement */}
                <a
                    href="https://societea.io"
                    className="internal-link flex justify-center px-2 py-2 text-[10px] font-light bg-transparent hover:underline mt-auto"
                >
                    Societea, Inc. © 2024. All rights reserved.
                </a>
            </CustomDrawer>

            {/* Mobile sidebar */}             
            <CustomDrawer
                anchor="left"
                open={isOpen}
                onClose={toggleDrawer(false)}
                className="block xl:hidden"
                classes={{
                    paper: "bg-white dark:bg-eoe-dark-bg transition-colors duration-200"
                }}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: 240,
                        bgcolor: 'background.paper',
                        '.dark &': {
                            bgcolor: 'background.default',
                        }
                    }
                }}
            >
    {/* Motto chip */}
    <div className="mx-2 my-2 px-3 py-1.5 bg-[#005286] font-semibold text-white text-xs rounded-full text-center">
        United we stand, prioritized they fall
    </div>

    {/* Navigation menu */}
    <nav className="flex flex-col text-sm font-light text-eoe-gray dark:text-eoe-dark-text-2">
                    {/* Home link */}
                    <div className="flex flex-col px-2 ">
                        <button 
                            onClick={() => handleNavigation('/')}
                            className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                        >
                            <Home size={20} />
                            <span>Home</span>
                        </button>

                        {/* Feedback link */}
                        <button 
                            onClick={() => handleNavigation('/feedback')}
                            className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                        >
                            <MessageSquarePlus size={20} />
                            <span>Feedback</span>
                        </button>
                    </div>

                    {/* Divider */}
                    <div className="mx-2 my-2 list-divider" />

                    {/* Admin section */}
                    {currentUser && currentUser.attributes.is_admin && (
                        <div className="flex flex-col px-2 ">
                            <span className="px-2 py-2 text-gray-500 dark:text-gray-400 font-light">
                                ADMIN
                            </span>
                            
                            <button 
                                onClick={() => handleNavigation('/admin')}
                                className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                            >
                                <ShieldCheck size={20} />
                                <span>Admin Home</span>
                            </button>

                            <button 
                                onClick={() => handleNavigation('/admin/reports')}
                                className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                            >
                                <Flag size={20} />
                                <span>Reported Items</span>
                            </button>

                            <button 
                                onClick={() => handleNavigation('/admin/feedback')}
                                className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                            >
                                <Bug size={20} />
                                <span>User Feedback</span>
                            </button>
                            <div className="mx-2 my-2 list-divider" />
                        </div>
                    )}

                    {/* Policies section */}
                    <div className="flex flex-col px-2 ">
                        <span className="px-2 py-2 text-gray-500 dark:text-gray-400 font-light">
                            RESOURCES
                        </span>
                        <a href="https://societea.io" className="menu-item-alt">
                            <button
                                className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                            >
                                <HeartHandshake size={20} />
                                <span>Powered by Societea</span>
                            </button>
                        </a>

                        <a href="https://societea.io/careers" className="menu-item-alt">
                            <button 
                                className="flex items-center gap-3 px-4 py-2"
                            >
                                <BriefcaseBusiness size={20} />
                                <span>Help Build Societea</span>
                            </button>
                        </a>            

                        <a href="mailto:support@societea.io" className="menu-item-alt">
                            <button 
                                className="flex items-center gap-3 px-4 py-2"
                            >
                                <MessageCircleQuestion size={20} />
                                <span>Help</span>
                            </button>
                        </a>
                    </div>
                    
                    <div className="mx-2 my-2 list-divider" />

                    <div className="flex flex-col px-2 ">
                        <span className="px-2 py-2 text-gray-500 dark:text-gray-400 font-light">
                            PRIVACY & SAFETY
                        </span>
                        <a href="https://societea.io/policies/user-agreement" className="menu-item-alt">
                            <button
                                className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                            >
                                <IconContentPolicy className="size-5" />
                                <span>Content Policy</span>
                            </button>
                        </a>

                        <button 
                            onClick={() => handleNavigation('/policies/privacy-policy')}
                            className="flex items-center gap-3 px-4 py-2 menu-item-alt"
                        >
                            <Scale size={20} />
                            <span>Privacy Policy</span>
                        </button>

                        <a href="https://societea.io/policies/user-agreement" className="menu-item-alt">
                            <button 
                                className="flex items-center gap-3 px-4 py-2"
                            >
                                <IconUserAgreement className="size-5" />
                                <span>User Agreement</span>
                            </button>
                        </a>
                    </div>
                </nav>

    {/* Footer */}
    <a
        href="https://societea.io"
        className="mt-auto flex justify-center px-2 py-2 text-[10px] text-gray-500 hover:underline"
    >
        Societea, Inc. © 2024. All rights reserved.
    </a>
</CustomDrawer>
        </div>
    )
}

export default SideNavBar