import React, { useEffect, useState } from 'react'
import api from 'config/axiosConfig'
import EnemyCard from 'components/Enemies/EnemyCard'
import AddEnemyToSolution from 'components/Enemies/AddEnemyToSolution'
import { Enemy } from 'util/constants'
import { Loader } from 'lucide-react'
import AddButton from 'ui/AddButton'

interface Props {
  solutionId: number
}

interface RelatedEnemy extends Enemy {
  enemy_id: number
}

const SolutionEnemies = ({ solutionId }: Props) => {
  const [relatedEnemies, setRelatedEnemies] = useState<RelatedEnemy[] | null>(null)
  const [error, setError] = useState('')
  const [isAddEnemyModalOpen, setAddEnemyModalOpen] = useState(false)

  useEffect(() => {
    const fetchRelatedEnemies = async () => {
      try {
        const response = await api.get<RelatedEnemy[]>(
          `/api/solutions/${solutionId}/enemies`
        )
        const enemyIds = response.data.map((enemy) => enemy.enemy_id)
        if (enemyIds.length > 0) {
          const enemiesResponse = await api.get<RelatedEnemy[]>(
            `/api/enemies?enemyIds=${JSON.stringify(enemyIds)}`
          )
          setRelatedEnemies(enemiesResponse.data)
        } else {
          setRelatedEnemies([])
        }
      } catch (err) {
        setError('Failed to load related enemies. Please try again later.')
      }
    }

    fetchRelatedEnemies()
  }, [solutionId])

  const handleEnemyAdded = (newEnemy: RelatedEnemy) => {
    setRelatedEnemies((prevEnemies) =>
      prevEnemies ? [...prevEnemies, newEnemy] : [newEnemy]
    )
  }

  if (error) {
    return <div className="text-red-500 text-sm">{error}</div>
  }

  if (relatedEnemies === null) {
    return (
      <div className="flex justify-center items-center h-16">
        <Loader className="animate-spin" />
      </div>
    )
  }

  return (
    <div className="related-enemies-container">
      <AddEnemyToSolution
        solutionId={solutionId.toString()}
        onEnemyAdded={handleEnemyAdded}
        open={isAddEnemyModalOpen}
        onClose={setAddEnemyModalOpen}
      />
      <div className="flex items-center mx-2 mb-4">
        <AddButton
          tooltipTitle='Suggest this solution for an enemy'
          onClick={() => setAddEnemyModalOpen(true)}
          buttonText='Enemy'
        />
      </div>
      {relatedEnemies.length > 0 ? (
        <div className="entity-divider">
          {relatedEnemies.map((enemy) => (
            <EnemyCard
              key={enemy.id}
              enemy={enemy}
              showMeta={true}
              showActions={true}
              onVote={() => {}}
              truncateDescription={true}
            />
          ))}
        </div>
      ) : (
        <p className="text-eoe-text dark:text-eoe-dark-text">
          No related enemies found.
        </p>
      )}
    </div>
  )
}

export default SolutionEnemies
