import React, { useEffect, useState } from 'react'
import { useUser } from 'util/UserContext'
import api from 'config/axiosConfig'
import AddImpact from './AddImpact'
import ImpactCard from './ImpactCard'
import AddButton from 'ui/AddButton'
import { Loader } from 'lucide-react'

import {
  Enemy,
  Impact,
  Scope,
  SortField,
  SortOrder,
} from 'util/constants'

interface Props {
  enemy: Enemy
}

const ImpactsSection = ({ enemy }: Props) => {
  const { currentUser, setShowAuthModal } = useUser()
  const [impacts, setImpacts] = useState<Impact[]>([])
  const [error, setError] = useState<string>('')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [scopeFilter, setScopeFilter] = useState<Scope | 'All'>('All')
  const [impactTypeFilter, setImpactTypeFilter] = useState<
    'All' | 'impact_negative' | 'impact_positive'
  >('All')
  const [sortField, setSortField] = useState<SortField>('updated_ts')
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc')

  useEffect(() => {
    const fetchImpacts = async () => {
      try {
        const response = await api.get<Impact[]>(
          `/api/impacts/enemies/${enemy.id}`
        )
        setImpacts(response.data)
      } catch (err) {
        setError('Failed to load impacts. Please try again later.')
      }
    }

    if (enemy) {
      fetchImpacts()
    }
  }, [enemy])

  const handleImpactAdded = async () => {
    // Re-fetch impacts after an impact is added
    const response = await api.get<Impact[]>(
      `/api/impacts/enemies/${enemy.id}`
    )
    setImpacts(response.data)
  }

  const handleSortChange = (field: SortField) => {
    const order: SortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
    setSortField(field)
    setSortOrder(order)
  }

  const handleAddImpactButtonClick = () => {
    if (!currentUser) {
      setShowAuthModal(true)
    } else {
      setOpenModal(true)
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const filteredImpacts = impacts.filter((impact) => {
    const matchesScope =
      scopeFilter === 'All' || impact.scope === scopeFilter
    const matchesImpactType =
      impactTypeFilter === 'All' || impact.rel_type === impactTypeFilter
    return matchesScope && matchesImpactType
  })

  const sortedImpacts = filteredImpacts.sort((a, b) => {
    if (sortOrder === 'asc') {
      return (
        new Date(a[sortField]).getTime() -
        new Date(b[sortField]).getTime()
      )
    } else {
      return (
        new Date(b[sortField]).getTime() -
        new Date(a[sortField]).getTime()
      )
    }
  })

  if (error) {
    return <div className="error-message">{error}</div>
  }

  if (!enemy) {
    return (
      <div className="flex justify-center items-center my-4">
        <Loader className="animate-spin h-12 w-12 text-eoe-gray dark:text-white" />
      </div>
    )
  }

  return (
    <div>
      {openModal && (
        <AddImpact
          enemyId={enemy.id}
          onImpactAdded={handleImpactAdded}
          onClose={handleCloseModal}
        />
      )}
      <div className="flex items-center justify-between mb-1">
        <div className="flex gap-1">
          <select
            value={scopeFilter || ''}
            onChange={(e) => setScopeFilter(e.target.value as Scope)}
            className="entity-filter-select"
          >
            <option value="All">Scope</option>
            <option value="Local">Local</option>
            <option value="National">National</option>
            <option value="Global">Global</option>
          </select>
          <select
            value={impactTypeFilter}
            onChange={(e) =>
              setImpactTypeFilter(
                e.target.value as 'All' | 'impact_negative' | 'impact_positive'
              )
            }
            className="entity-filter-select"
          >
            <option value="All">Type</option>
            <option value="impact_negative">Negative</option>
            <option value="impact_positive">Positive</option>
          </select>
          <button
            type="button"
            className="btn-secondary text-sm"
            onClick={() => handleSortChange('updated_ts')}
          >
            Updated {sortOrder === 'asc' ? '▲' : '▼'}
          </button>
        </div>
        <AddButton
          tooltipTitle="Add Impact"
          buttonText="Impact"
          className="add-button"
          onClick={handleAddImpactButtonClick}
        />
      </div>
      {impacts.length === 0 ? (
        <p className="mt-2">No impacts exist for this enemy.</p>
      ) : (
        <div className="flex flex-col divide-y divide-eoe-border-main dark:divide-eoe-dark-border-main">
          {sortedImpacts.map((impact) => (
            <ImpactCard
              key={impact.id}
              impact={impact}
              truncateDescription={true}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default ImpactsSection
