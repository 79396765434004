import React, { useState, useEffect } from 'react'
import { X } from 'lucide-react'
import api from 'config/axiosConfig'
import { Enemy } from 'util/constants'

interface Props {
    solutionId: string
    onEnemyAdded: (value: Enemy) => void
    open: boolean
    onClose: (value: boolean) => void
}

const AddEnemyToSolution = ({ solutionId, onEnemyAdded, open, onClose }: Props) => {
    const [searchQuery, setSearchQuery] = useState('')
    const [searchResults, setSearchResults] = useState<Enemy[]>([])
    const [selectedEnemy, setSelectedEnemy] = useState<Enemy | null>(null)
    const [error, setError] = useState('')

    useEffect(() => {
        if (searchQuery.length > 0) {
            const fetchEnemies = async () => {
                try {
                    const response = await api.get(
                        `/api/enemies?search=${encodeURIComponent(searchQuery)}`
                    )
                    setSearchResults(response.data)
                } catch (error) {
                    console.error('Error fetching enemies:', error)
                }
            }
            fetchEnemies()
        } else {
            setSearchResults([])
        }
    }, [searchQuery])

    const handleAddEnemy = async () => {
        if (!selectedEnemy) {
            setError('Please select an enemy.')
            return
        }

        try {
            await api.put(
                `/api/solutions/link/${solutionId}/${selectedEnemy.id}`
            )
            onEnemyAdded(selectedEnemy)
            setSearchQuery('')
            setSearchResults([])
            setSelectedEnemy(null)
            setError('')
            onClose(false)
        } catch (error) {
            console.error('Error adding enemy to solution:', error)
            setError('Failed to add enemy to solution. Please try again.')
        }
    }

    return (
        <div>
            {open && (
                <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                    onClick={() => onClose(false)}
                >
                    <div
                        className="modal"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Close icon in the top right corner */}
                        <button
                            type="button"
                            onClick={() => onClose(false)}
                            className="modal-close-button"
                        >
                            <X className="modal-close-icon" />
                        </button>
                        <h4 className="text-xl font-semibold text-eoe-gray dark:text-eoe-dark-text mb-2">
                            Suggest Solution for Enemy
                        </h4>
                        <div className="flex flex-col gap-2">
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search enemies..."
                                className="input-text"
                            />
                            {error && <div className="error-message">{error}</div>}
                            {searchResults && searchResults.length > 0 && (
                                <ul className="search-results">
                                    {searchResults.map((enemy) => (
                                        <li
                                            key={enemy.id}
                                            className={`search-result ${
                                                selectedEnemy && selectedEnemy.id === enemy.id
                                                    ? 'search-result-selected'
                                                    : ''
                                            }`}
                                            onClick={() => setSelectedEnemy(enemy)}
                                        >
                                            {enemy.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            <div className="flex gap-2 justify-end mt-2">
                            <button
                                type="button"
                                onClick={() => onClose(false)}
                                className="btn-secondary"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                onClick={handleAddEnemy}
                                disabled={!selectedEnemy}
                                className={
                                    !selectedEnemy
                                        ? 'btn-primary-disabled'
                                        : 'btn-primary'
                                }
                            >
                                Submit
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AddEnemyToSolution
