import React from 'react'
import { useParams, NavLink } from 'react-router-dom'
import CommentsSection from 'components/Comments/CommentsSection'
import SolutionsSection from 'components/Solutions/SolutionsSection'
import ImpactsSection from 'components/Impacts/ImpactsSection'
import { Enemy } from 'util/constants'

interface Props {
    enemy: Enemy
}

const EnemyProfileTabs = ({ enemy }: Props) => {
    const { name, tab } = useParams<{ name: string; tab?: string }>()
    const selectedTab = tab

    return (
        <div className="grid grid-rows-auto grid-flow-row gap-2">
            <div className="p-2">
                <nav className="flex space-x-4 overflow-x-auto whitespace-nowrap">
                    <NavLink
                        to={`/enemy/${name}/comments`}
                        end
                        className={({ isActive }) =>
                            isActive || !tab ? 'tab-active' : 'tab-inactive'
                        }
                    >
                        Comments
                    </NavLink>
                    <NavLink
                        to={`/enemy/${name}/impacts`}
                        className={({ isActive }) =>
                            isActive ? 'tab-active' : 'tab-inactive'
                        }
                    >
                        Impacts
                    </NavLink>
                    <NavLink
                        to={`/enemy/${name}/solutions`}
                        className={({ isActive }) =>
                            isActive ? 'tab-active' : 'tab-inactive'
                        }
                    >
                        Solutions
                    </NavLink>
                </nav>
            </div>
            <div className="grid grid-rows-auto grid-flow-row gap-2">
                {(selectedTab === 'comments' || !tab) && (
                    <CommentsSection
                        commentable_id={enemy.id}
                        commentable_type="enemy"
                    />
                )}
                {selectedTab === 'impacts' && <ImpactsSection enemy={enemy} />}
                {selectedTab === 'solutions' && (
                    <SolutionsSection enemy={enemy} />
                )}
            </div>
        </div>
    )
}

export default EnemyProfileTabs
