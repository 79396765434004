import React, { useState } from 'react'
import { useUser } from 'util/UserContext'
import TextEditor from 'components/TextEditor'
import api from 'config/axiosConfig'
import { X } from 'lucide-react'
import AuthModal from 'components/Auth/AuthModal'
import { Solution } from 'util/constants'

interface Props {
  enemyId: number
  onSolutionAdded: (solution: Solution) => void
  onClose: () => void
}

const CreateSolution = ({ enemyId, onSolutionAdded, onClose }: Props) => {
  const { currentUser, setShowAuthModal } = useUser()
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [referenceLink, setReferenceLink] = useState<string>('')
  const [error, setError] = useState<string>('')

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!currentUser) {
      setShowAuthModal(true)
      return
    }

    try {
      const response = await api.post<Solution>(`/api/solutions/${enemyId}`, {
        name,
        description,
        reference_link: referenceLink,
        user_id: currentUser.id,
      })

      const newSolution = response.data

      // Automatically upvote the solution
      await api.post(`/api/votes/solution/${newSolution.id}`, {
        user_id: currentUser.id,
        rel_type: 'user_upvote',
      })

      onSolutionAdded(newSolution)
      setName('')
      setDescription('')
      setReferenceLink('')
      setError('')
      onClose() // Close the modal after successful submission
    } catch (error) {
      console.error('Error creating solution:', error)
      setError('Failed to create solution. Please try again later.')
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Overlay */}
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      {/* Modal Content */}
      <div className="modal relative">
        {/* Close Button */}
        <button onClick={onClose} className="modal-close-button">
          <X className="modal-close-icon" />
        </button>
        <form onSubmit={handleSubmit}>
          <div className="form-control">
            <label className="form-label">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="input-text w-full"
              required
            />
          </div>
          <div className="form-control">
            <label className="form-label">Description</label>
            <TextEditor
              value={description}
              onChange={setDescription}
              editorType="entity"
            />
          </div>
          <div className="form-control">
            <label className="form-label">Reference Link</label>
            <input
              type="url"
              value={referenceLink}
              onChange={(e) => setReferenceLink(e.target.value)}
              className="input-text w-full"
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <div className="flex justify-end space-x-2 mt-4">
            <button
              type="button"
              className="btn-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
            <button type="submit" className="btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
      {/* Authentication Modal */}
      {setShowAuthModal && (
        <AuthModal
          show={setShowAuthModal}
          onClose={() => setShowAuthModal(false)}
        />
      )}
    </div>
  )
}

export default CreateSolution
