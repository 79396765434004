import React, { useState, useRef } from 'react';
import { useUser } from 'util/UserContext';
import AvatarEditor from 'react-avatar-editor';
import {
    Modal,
    ModalDialog,
    DialogContent,
    DialogTitle,
    Button,
    Stack,
    Avatar,
    Slider,
} from '@mui/joy';
import api from 'config/axiosConfig';

interface Props {
    onClose: () => void;
}

const UserAvatarModal = ({ onClose }: Props) => {
    const { currentUser } = useUser();
    const [image, setImage] = useState<File | null>(null);
    const [scale, setScale] = useState<number>(1);
    const [rotate, setRotate] = useState<number>(0);
    const [avatarKey, setAvatarKey] = useState(currentUser?.properties_public?.avatar_key);
    const editorRef = useRef<AvatarEditor>(null);
    const IMAGE_CDN = process.env.REACT_APP_IMAGE_CDN;

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.[0]) {
            setImage(event.target.files[0]);
        }
    };

    const handleSave = () => {
        if (editorRef.current) {
            editorRef.current.getImageScaledToCanvas().toBlob((blob) => {
                if (blob) {
                    const formData = new FormData();
                    formData.append('image', blob, 'profile-picture.png');

                    api.post('/api/user/avatar', formData)
                        .then((response) => {
                            setAvatarKey(response.data.key);
                            setImage(null);
                        })
                        .catch((error) => {
                            console.error('Error uploading profile picture:', error);
                        });
                }
            }, 'image/png');
        }
    };

    const handleClose = () => {
        onClose();
        window.location.reload();
    };

    return (
        <Modal open onClose={handleClose}>
            <ModalDialog size="lg">
                <DialogTitle>Avatar image</DialogTitle>
                <DialogContent>
                    {!image ? (
                        <Stack spacing={2} alignItems="center">
                            <Avatar
                                src={avatarKey ? `${IMAGE_CDN}/${avatarKey}` : undefined}
                                alt={currentUser?.username || "Profile"}
                                sx={{ width: 100, height: 100 }}
                            />
                            <Button component="label">
                                Select a new image
                                <input type="file" hidden accept="image/*" onChange={handleFileChange} />
                            </Button>
                        </Stack>
                    ) : (
                        <Stack spacing={2} alignItems="center">
                            <AvatarEditor
                                ref={editorRef}
                                image={image}
                                width={250}
                                height={250}
                                border={50}
                                borderRadius={125}
                                color={[255, 255, 255, 0.6]}
                                scale={scale}
                                rotate={rotate}
                            />
                            <Stack spacing={2} sx={{ width: '100%' }}>
                                <Stack spacing={2} direction="row" alignItems="center">
                                    <span>Zoom:</span>
                                    <Slider
                                        value={scale}
                                        min={1}
                                        max={3}
                                        step={0.01}
                                        onChange={(_, value) => setScale(value as number)}
                                    />
                                </Stack>
                                <Stack spacing={2} direction="row" alignItems="center">
                                    <span>Rotate:</span>
                                    <Slider
                                        value={rotate}
                                        min={0}
                                        max={360}
                                        step={1}
                                        onChange={(_, value) => setRotate(value as number)}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                    <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
                        <Button variant="plain" onClick={handleClose}>
                            {image ? 'Cancel' : 'Close'}
                        </Button>
                        {image && (
                            <Button onClick={handleSave} disabled={!image && !avatarKey}>
                                Save
                            </Button>
                        )}
                    </Stack>
                </DialogContent>
            </ModalDialog>
        </Modal>
    );
};

export default UserAvatarModal;