import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Tab, TabProps } from '@mui/joy';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  flex: 1;
`;

const StyledTab = styled(Tab)`
  flex: 1;
  justify-content: center;
`;

interface TabLinkProps extends TabProps {
  to: LinkProps['to'];
}

const TabLink: React.FC<TabLinkProps> = ({ to, children, ...tabProps }) => (
  <StyledLink to={to}>
    <StyledTab {...tabProps}>
      {children}
    </StyledTab>
  </StyledLink>
);

export default TabLink;