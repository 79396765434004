import { useNavigate } from 'react-router-dom'
import EntitiesSort from './EntitiesSort'
import { Box } from '@mui/joy'
import { Sort } from 'util/constants'

interface Props {
    sort: Sort
    searchParams: URLSearchParams
}

const SolutionsListSelector = ({ sort, searchParams }: Props) => {
    const navigate = useNavigate()

    const handleSortChange = (newSort: Sort) => {
        const newSearchParams = new URLSearchParams(searchParams)
        navigate(`/solutions/${newSort}?${newSearchParams.toString()}`)
    }

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <EntitiesSort entityType="solutions" sort={sort} onSortChange={handleSortChange} />
        </Box>
    )
}

export default SolutionsListSelector