import { useEffect } from 'react'

const GoogleSignin = ({ handleCredentialResponse }) => {
    useEffect(() => {
        const loadGoogleSignin = () => {
            const script = document.createElement('script')
            script.src = 'https://accounts.google.com/gsi/client'
            script.async = true
            script.defer = true
            document.body.appendChild(script)

            script.onload = () => {
                // Check if dark mode is enabled
                const isDarkMode = document.documentElement.classList.contains('dark')

                window.google.accounts.id.initialize({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                    callback: handleCredentialResponse,
                })

                window.google.accounts.id.renderButton(
                    document.getElementById('google-signin-button'),
                    {
                        theme: isDarkMode ? 'outline' : 'outline',
                        size: 'large',
                        width: document.getElementById('google-signin-button').offsetWidth,
                        text: 'continue_with',
                        shape: 'pill',
                        logo_alignment: 'center',
                        type: 'standard',
                    }
                )
            }
        }

        loadGoogleSignin()

        // Re-render button when theme changes
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.target.classList.contains('dark')) {
                    loadGoogleSignin()
                }
            })
        })

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        })

        return () => observer.disconnect()
    }, [handleCredentialResponse])

    return (
        <div className="w-full">
            <div 
                id="google-signin-button" 
                className="w-full rounded-full overflow-hidden bg-transparent dark:bg-eoe-dark-icon"
            />
        </div>
    )
}

export default GoogleSignin