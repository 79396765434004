// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
.expand-collapse-button {
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 50%;
  padding: 4px;
  z-index: 10;
}*/

.comment-content {
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word; /* Legacy property for IE */
  word-break: break-word; /* For non-webkit browsers */
  hyphens: auto;
}

.comment-content img {
  max-width: 100%;
}

.comment-content iframe {
  max-width: 100%;
}

.comment-content p,
.comment-content div,
.comment-content span {
  max-width: 100%;
  white-space: pre-wrap; /* Preserves whitespace and allows wrapping */
}`, "",{"version":3,"sources":["webpack://./src/components/Comments/Comment.css"],"names":[],"mappings":"AAAA;;;;;;;;;;EAUE;;AAEF;EACE,eAAe;EACf,yBAAyB;EACzB,qBAAqB,EAAE,2BAA2B;EAClD,sBAAsB,EAAE,4BAA4B;EACpD,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;;;EAGE,eAAe;EACf,qBAAqB,EAAE,6CAA6C;AACtE","sourcesContent":["/*\r\n.expand-collapse-button {\r\n  position: absolute;\r\n  left: 50%;\r\n  top: 40px;\r\n  transform: translateX(-50%);\r\n  background-color: #fff;\r\n  border-radius: 50%;\r\n  padding: 4px;\r\n  z-index: 10;\r\n}*/\r\n\r\n.comment-content {\r\n  max-width: 100%;\r\n  overflow-wrap: break-word;\r\n  word-wrap: break-word; /* Legacy property for IE */\r\n  word-break: break-word; /* For non-webkit browsers */\r\n  hyphens: auto;\r\n}\r\n\r\n.comment-content img {\r\n  max-width: 100%;\r\n}\r\n\r\n.comment-content iframe {\r\n  max-width: 100%;\r\n}\r\n\r\n.comment-content p,\r\n.comment-content div,\r\n.comment-content span {\r\n  max-width: 100%;\r\n  white-space: pre-wrap; /* Preserves whitespace and allows wrapping */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
