import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { transformHtmlContent } from 'util/transformHtmlContent';
import { ChevronsDown, ChevronsUp } from 'lucide-react';
import EntityActions from 'components/Entities/EntityActions'
import Report from 'components/Entities/Report'
import { Menu, Chip, Tooltip, Avatar } from '@mui/joy'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import { Enemy } from 'util/constants'
import EntityThumbnail from 'components/Entities/EntityThumbnail'
import ResponsiveTruncatedText from 'ui/ResponsiveTruncatedText';
import { formatDate, formatRelativeTime } from 'config/dayjsConfig';

interface Props {
    enemy: Enemy
    showMeta: boolean
    truncateDescription: boolean | null
    isEnemyProfilePage?: boolean
    showActions?: boolean
    onVote?: () => void
}

interface WikiSummary {
    thumbnail?: {
        source: string
        width: number
        height: number
    }
    originalimage?: {
        source: string
        width: number
        height: number
    }
    extract: string | null
}

const EnemyCard = ({
    enemy,
    showMeta = true,
    truncateDescription = true,
    isEnemyProfilePage = false,
}: Props) => {
    const navigate = useNavigate()
    const [isExpanded, setIsExpanded] = useState(false)
    const enemyHref = `/enemy/${encodeURIComponent(enemy.name.replace(/ /g, '_'))}`
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)
    const moreIconRef = useRef<HTMLButtonElement>(null)
    const menuRef = useRef<HTMLDivElement>(null);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false)
    const reportModalRef = useRef<HTMLDivElement>(null)
    const [extract, setExtract] = React.useState<string | null>(null)
    const [imageUrl, setImageUrl] = React.useState<string | null>(null)
    const [isTruncated, setIsTruncated] = useState(false);
    const TruncateMarkup = require('react-truncate-markup').default;
    
    const IMAGE_CDN = process.env.REACT_APP_IMAGE_CDN;

    useEffect(() => {
        const fetchSummary = async () => {
            try {
                const response = await fetch(
                    `https://en.wikipedia.org/api/rest_v1/page/summary/${enemy.name}`
                )
                const data: WikiSummary = await response.json()
                const imageSource =
                    data.thumbnail?.source || data.originalimage?.source || null
                setImageUrl(imageSource)
                setExtract(data.extract)
            } catch (error) {
                console.error('Error fetching Wikipedia summary:', error)
            }
        }

        fetchSummary()
    }, [enemy.name, extract])

    const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const element = event.target as HTMLElement;
        if (element.tagName.toLowerCase() === 'a') return;    
        if (element.classList.contains('read-more')) return;
        if (!isEnemyProfilePage) {
          navigate(enemyHref);
        } else {          
            event.preventDefault();  
            if (element.tagName.toLowerCase() === 'img') {
                event.stopPropagation();
                window.open(enemy.wikipedia_link, '_blank', 'noopener,noreferrer');
            } else {            
                return;
            }
        }
    };
    
      const handleDescriptionClick = (event: React.MouseEvent) => {
        if (!truncateDescription || !isTruncated) return;
        event.preventDefault();
        if (isEnemyProfilePage && truncateDescription) {
          setIsExpanded(!isExpanded);
        } else {
          return;
        }
      };
    
      const handleReadMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsExpanded(!isExpanded);
        event.stopPropagation(); // Prevent the click from bubbling up to the card
      };
    const handleMenuToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (anchorEl) {
            setAnchorEl(null) // Close the menu if it's already open
        } else {
            setAnchorEl(event.currentTarget) // Open the menu
        }
    }

    const handleReportClick = () => {
        handleMenuClose()  // Close the menu
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        setIsReportModalOpen(false)
    }

    // Close the menu if clicked outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          const element = event.target as HTMLElement;
          if (
            anchorEl &&
            !moreIconRef.current?.contains(element) &&
            !menuRef.current?.contains(element) &&
            !reportModalRef.current?.contains(element)
          ) {
            handleMenuClose();
          }
        };
      
        document.addEventListener('mousedown', handleClickOutside);
      
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [anchorEl]);

    const truncateEllipsis = (node: any) => {
        return (
          <span className="read-more">
          <button 
            className="read-more-border w-full text-sm rounded-md border-b-2 justify-center items-center px-1"
            onClick={handleReadMoreClick}
          >
          <span className="flex justify-center items-center ">
              <ChevronsDown className="flex justify-center items-center"/>
              </span>
            </button>
              </span>
        );
      };
    
      const renderDescription = () => {
        if (truncateDescription && !isExpanded) {
          return (
          <TruncateMarkup lines={10} tokenize="words" ellipsis={truncateEllipsis} onTruncate={(wasTruncated: any) => setIsTruncated(wasTruncated)}>
            <div>
              {transformHtmlContent(extract || '')}
            </div>
          </TruncateMarkup>
          );
        } else {
            return (
                <div>
                    {transformHtmlContent(extract || '')}
                    {isTruncated && (
                        <>
                            <span className="flex">
                                <button 
                                    className="read-more-border w-full text-sm rounded-md border-b-2 justify-center items-center px-1"
                                    onClick={handleReadMoreClick}
                                >
                                    <span className="flex justify-center items-center ">
                                        <ChevronsUp className="flex justify-center items-center" />
                                    </span>
                                </button>
                            </span>
                        </>
                    )}
                </div>
            );
        }
      };
    return (
        <div className="card-container">
            <article>
                <div className="card-main md:rounded-lg flex flex-col p-4 md:my-1 cursor-pointer md:flex-row flex-wrap relative">
                    <div className="entity-actions-container order-last md:order-first md:w-20">
                        <EntityActions
                            entity={enemy}
                            entityType="enemy"
                        />
                    </div>
                    <div className="card-content order-first md:order-last md:ml-4">
                        {showMeta && (
                            <div className="card-meta text-sm flex items-center justify-between relative">
                            <div className="flex items-center">
                              <Tooltip title={`See ${enemy.username}'s profile`} arrow>
                                <Link
                                  to={`/user/${enemy.username}`}
                                  className="card-username"
                                >
                                  <Avatar
                                    alt={enemy.username || "Profile"}
                                    src={enemy.properties_public?.avatar_key ? `${IMAGE_CDN}/${enemy.properties_public.avatar_key}` : undefined}
                                    sx={{ width: 24, height: 24, marginRight: 0.5 }}
                                  />
                                  <span className="card-username">{enemy.username}</span>
                                </Link>
                              </Tooltip>
                              <Tooltip title={formatDate(enemy.created_ts)} arrow>
                                <span className="card-date">
                                  {`• ${formatRelativeTime(enemy.created_ts)}`}
                                </span>
                              </Tooltip>
                            </div>
                                <div className="icon-more text-xl">
                                    <button
                                        className="card-more size-9 rounded-full flex justify-center items-center"
                                        onClick={handleMenuToggle}
                                        ref={moreIconRef} 
                                    >
                                        <MoreHorizOutlinedIcon className="icon-stroke"/>
                                    </button>
                                </div>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleMenuClose}
                                    ref={menuRef}
                                >
                                    <Report
                                        entityId={enemy.id.toString()}
                                        entityType="enemy"
                                        isOpen={isReportModalOpen}
                                        setIsOpen={setIsReportModalOpen}
                                        modalRef={reportModalRef}
                                        onReportClick={handleReportClick}
                                    />
                                </Menu>
                            </div>
                        )}
                        <div
                            className="card-header text-xl font-semibold mt-1"
                            onClick={handleCardClick}
                        >
                            <Link to={enemyHref} className="card-title">
                                {enemy.name}
                            </Link>
                            <div className="card-tags flex flex-row gap-2 my-1">
                                <Chip
                                component={'div' as any}
                                label="Enemy"
                                color="neutral"
                                variant="soft"
                                size="md"
                                sx={{
                                    backgroundColor: '#cf1f1f',
                                    color: '#fff',
                                    fontSize: '0.75rem',
                                    }}
                                >
                                    Enemy
                                </Chip>
                                {enemy.properties?.type && (
                                    <Chip
                                    component={'div' as any}
                                    label={enemy.properties.type}
                                    color="neutral"
                                    variant="soft"
                                    size="md"
                                    sx={{
                                        backgroundColor: '#555555',
                                        color: '#fff',
                                        fontSize: '0.75rem',
                                    }}
                                >
                                        {enemy.properties.type}
                                    </Chip>
                                )}
                            </div>
                        </div>
                        <div className="card-description flex flex-col-reverse md:flex-row gap-2" 
                                onClick={handleCardClick}>
                            <div
                            >
                                <span onClick={handleDescriptionClick}>
                                    {renderDescription()}
                                </span>
                            </div>
                            <EntityThumbnail 
                                url={imageUrl || ''}
                                urlType="img"
                                isProfilePage={isEnemyProfilePage}
                                onCardClick={handleCardClick}
                                referenceLink={enemy.wikipedia_link}
                            />
                        </div>
                        {enemy.wikipedia_link && (
                        <ResponsiveTruncatedText
                            text={enemy.wikipedia_link}
                            className="external-link block text-xs py-4 lg:pt-0"
                            href={enemy.wikipedia_link}
                            target="_blank"
                            rel="noopener noreferrer"
                        />
                        )}
                    </div>
                </div>
            </article>
        </div>
    )
}

export default EnemyCard
