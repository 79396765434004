import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import api from 'config/axiosConfig'
import { useNavigate } from 'react-router-dom'
import { Loader } from 'lucide-react'
import TextEditor from 'components/TextEditor'
import { Solution } from 'util/constants'

interface Props {
  solution: Solution
  description: string
  referenceLink: string
  editMode?: boolean
}

const SolutionEditor = ({
  solution,
  description,
  referenceLink,
  editMode = true,
}: Props) => {
  const { currentUser, setShowAuthModal } = useUser()
  const navigate = useNavigate()
  const [newDescription, setDescription] = useState<string>(description)
  const [newReferenceLink, setReferenceLink] = useState<string>(referenceLink)
  const [authorized, setAuthorized] = useState<boolean>(true)

  useEffect(() => {
    if (!currentUser) {
      setAuthorized(false)
      setShowAuthModal(true)
    } else if (currentUser?.id !== solution.user_id) {
      setAuthorized(false)
      navigate(`/solution/${solution.id}`)
      window.location.reload()
    }
  }, [currentUser, setShowAuthModal, solution, navigate])

  if (!authorized) {
    return null // Prevent rendering if not authorized
  }

  const handleReferenceLinkChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReferenceLink(e.target.value)
  }

  const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    try {
      await api.patch(`/api/solutions/id/${solution.id}`, {
        description: newDescription,
        reference_link: newReferenceLink,
      })
      navigate(`/solution/${solution.id}`)
      window.location.reload()
    } catch (error) {
      console.error('Error saving solution:', error)
    }
  }

  const handleCancel = () => {
    navigate(`/solution/${solution.id}`)
    window.location.reload()
  }

  if (!solution) {
    return (
      <div className="flex justify-center items-center my-4">
        <Loader className="animate-spin h-12 w-12 text-eoe-gray dark:text-white" />
      </div>
    )
  }

  return (
    <div className="mt-4 mb-4">
      <div>
        {solution && (
          <div className="edit-card">
            <h3 className="text-xl font-semibold mb-4">{solution.name}</h3>
            <div className="form-control">
              <label className="form-label">Description</label>
              <TextEditor
                value={newDescription}
                onChange={setDescription}
                editorType="entity"
              />
            </div>
            <div className="form-control">
              <label className="form-label">Reference Link</label>
              <input
                type="url"
                value={newReferenceLink}
                onChange={handleReferenceLinkChange}
                className="input-text"
              />
            </div>
            <div className="edit-actions">
              <button
                type="button"
                className="btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-primary"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
            {newReferenceLink && (
              <a
                href={newReferenceLink}
                target="_blank"
                rel="noopener noreferrer"
                className="external-link"
              >
                {newReferenceLink}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default SolutionEditor
