import { forwardRef } from 'react';
import { sanitize } from 'config/xssConfig';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import Box from '@mui/joy/Box';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import TextEditor from 'components/TextEditor';

interface CommentInputProps {
  onSubmit: (content: string) => void;
  onCancel?: () => void;
  buttonText: string;
  initialContent?: string;
}

interface FormInputs {
  content: string;
}

const CommentInput = forwardRef<HTMLDivElement, CommentInputProps>(({
  onSubmit,
  onCancel,
  buttonText,
  initialContent = ''
}, ref) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: { content: initialContent },
  });

  const onSubmitForm: SubmitHandler<FormInputs> = (data) => {
    const sanitizedContent = sanitize(data.content);
    onSubmit(sanitizedContent);
    reset({ content: '' });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} >
      <div className="mb-4 mt-2 flex flex-col gap-2">
        <Controller
          name="content"
          control={control}
          rules={{
            required: 'Content is required',
            validate: (value) => value.length >= 1 || 'Content must be at least 1 character long',
          }}
          render={({ field }) => (
            <TextEditor
              onChange={(newContent) => field.onChange(newContent)}
              value={field.value}
              editorType="comment"
            />
          )}
        />
        {errors.content && (
          <p className="mt-1 text-sm text-red-500">{errors.content.message}</p>
        )}
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 2
        }}>
          {onCancel && (
            <button
              type="button"
              onClick={onCancel}
              className="btn-secondary rounded-full px-4 py-3 mx-2 flex justify-content-end items-center gap-2 text-sm font-semibold hover:text-white cursor-pointer"
            >
              Cancel
            </button>
          )}
          <button
            type="submit"
            className="btn-primary rounded-full px-4 py-3 mx-2 flex justify-content-end items-center gap-1 text-sm font-semibold hover:text-white cursor-pointer"                
          >
            <span>
              <SendOutlinedIcon className="text-xl -rotate-45"/>
            </span> 
            {buttonText}
          </button>
        </Box>
      </div>
    </form>
  );
});

export default CommentInput;