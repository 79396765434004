import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import { Navigate } from 'react-router-dom'
import {
    Table,
    Sheet,
    Select,
    Option,
    Button,
    Typography,
    IconButton,
} from '@mui/joy'
import {
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Collapse,
    Box,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import api from 'config/axiosConfig'
import { Feedback } from 'util/constants'

interface AdminReportsProps {
    isAdmin: boolean
}

interface RowProps {
    feedback: Feedback
    handleStatusChange: (feedbackId: Feedback['id'], newStatus: Feedback['status']) => void
    handleSave: (feedbackId: Feedback['id']) => void
}

const FEEDBACK_STATUSES = [
    'Open',
    'Reviewing',
    'Planned',
    'Unplanned',
    'Developing',
    'Released',
    'Closed'
]

const Row = ({
    feedback,
    handleStatusChange,
    handleSave,
}: RowProps) => {
    const [open, setOpen] = useState(false)

    return (
        <React.Fragment>
            <TableRow className="dark:!bg-eoe-dark-bg" sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>{feedback.id}</TableCell>
                <TableCell>{feedback.username}</TableCell>
                <TableCell>{feedback.properties.category}</TableCell>
                <TableCell>
                    <a href={`/feedback/${feedback.id}`}>{feedback.title}</a>
                </TableCell>
                <TableCell>{feedback.vote_count}</TableCell>
                <TableCell>
                    <Select
                        value={feedback.status}
                        onChange={(_, value) =>
                            handleStatusChange(feedback.id, value as string)
                        }
                    >
                        {FEEDBACK_STATUSES.map((status) => (
                            <Option key={status} value={status}>
                                {status}
                            </Option>
                        ))}
                    </Select>
                </TableCell>
                <TableCell>
                    {new Date(feedback.created_ts).toLocaleString()}
                </TableCell>
                <TableCell>
                    {new Date(feedback.updated_ts).toLocaleString()}
                </TableCell>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="lg"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Button onClick={() => handleSave(feedback.id)}>Save</Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={10}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography
                                gutterBottom
                                component="div"
                                sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}
                            >
                                Details
                            </Typography>
                            <Table size="sm" aria-label="details">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Description
                                        </TableCell>
                                        <TableCell>
                                            {feedback.description}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

const AdminReports = ({ isAdmin }: AdminReportsProps) => {
    const { currentUser, awaitingUser } = useUser()
    const [feedbacks, setFeedbacks] = useState<Feedback[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        if (!awaitingUser && isAdmin) {
            fetchFeedbacks()
        }
    }, [awaitingUser, isAdmin])

    const fetchFeedbacks= async () => {
        try {
            const response = await api.get('/api/feedback')
            setFeedbacks(response.data)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching reports:', error)
            setError('Failed to fetch reports')
            setLoading(false)
        }
    }

    const handleStatusChange = (feedbackId: number, newStatus: string) => {
        setFeedbacks(
            feedbacks.map((feedback) =>
                feedback.id === feedbackId
                    ? { ...feedback, status: newStatus }
                    : feedback
            )
        )
    }

    const handleSave = async (feedbackId: number) => {
        const feedback = feedbacks.find((f) => f.id === feedbackId)
        if (!feedback) return

        try {
            await api.patch(`/api/feedback/${feedbackId}`, {
                status: feedback.status,
                category: feedback.properties.category,
                description: feedback.description,

            })
            // Optionally, you can fetch the feedback again to ensure the data is up-to-date
            await fetchFeedbacks()
        } catch (error) {
            console.error('Error updating feedback:', error)
            setError('Failed to update feedback')
        }
    }

    if (awaitingUser) {
        return <Sheet>Loading...</Sheet>
    }

    if (!currentUser || !currentUser.attributes?.is_admin) {
        return <Navigate to="/" />
    }

    if (loading) return <Typography>Loading...</Typography>

    if (error) return <Typography color="danger">{error}</Typography>

    return (
        <TableContainer className="dark:!bg-eoe-dark-bg">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Username</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Votes</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Updated</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {feedbacks.map((feedback) => (
                        <Row
                            key={feedback.id}
                            feedback={feedback}
                            handleStatusChange={handleStatusChange}
                            handleSave={handleSave}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default AdminReports
