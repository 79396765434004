import React, { useState } from 'react'
import { useUser } from 'util/UserContext'
import TextEditor from 'components/TextEditor'
import api from 'config/axiosConfig'
import { Impact, Scope } from 'util/constants'
import { X } from 'lucide-react'

interface Props {
  enemyId: number
  onImpactAdded: (impact: Impact) => void
  onClose: () => void
}

const AddImpact = ({ enemyId, onImpactAdded, onClose }: Props) => {
  const { currentUser, setShowAuthModal } = useUser()
  const [referenceLink, setReferenceLink] = useState<string>('')
  const [scope, setScope] = useState<Scope | ''>('')
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [impactType, setImpactType] = useState<
    'impact_negative' | 'impact_positive'
  >('impact_negative')
  const [error, setError] = useState<string>('')

  const handleAddImpact = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!currentUser) {
      setShowAuthModal(true)
      return
    }

    try {
      let standardizedLink = referenceLink.trim()

      // Check if the link starts with "https://", otherwise replace it
      if (!standardizedLink.startsWith('https://')) {
        standardizedLink = standardizedLink.replace(/^(.*?:\/\/)?/, 'https://')
      }

      const impactData = {
        reference_link: standardizedLink,
        scope,
        description,
        title,
        rel_type: impactType,
        user_id: currentUser.id,
      }

      const response = await api.post<Impact>(
        `/api/impacts/enemies/${enemyId}`,
        impactData
      )

      const newImpact = response.data

      // Automatically upvote the impact
      await api.post(`/api/votes/impact/${newImpact.impact_id}`, {
        user_id: currentUser.id,
        rel_type: 'user_upvote',
      })

      setError('')
      onImpactAdded(newImpact)
      setReferenceLink('')
      setScope('')
      setDescription('')
      setTitle('')
      onClose()
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        setShowAuthModal(true)
      } else {
        setError('Failed to add impact. Please try again.')
      }
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Overlay */}
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      {/* Modal Content */}
      <div className="modal relative">
        {/* Close Button */}
        <button onClick={onClose} className="modal-close-button">
          <X className="modal-close-icon" />
        </button>
        <form onSubmit={handleAddImpact}>
          <div className="form-control">
            <label className="form-label">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="input-text w-full"
              required
            />
          </div>
          <div className="form-control">
            <label className="form-label">Reference Link</label>
            <input
              type="url"
              value={referenceLink}
              onChange={(e) => setReferenceLink(e.target.value)}
              className="input-text w-full"
              required
            />
          </div>
          <div className="form-control">
            <label className="form-label">Scope</label>
            <select
              value={scope || ''}
              onChange={(e) => setScope(e.target.value as Scope)}
              className="input-select"
              required
            >
              <option value="" disabled>
                Select Scope
              </option>
              <option value="Local">Local</option>
              <option value="National">National</option>
              <option value="Global">Global</option>
            </select>
          </div>
          <div className="form-control">
            <label className="form-label">Impact Type</label>
            <select
              value={impactType}
              onChange={(e) =>
                setImpactType(
                  e.target.value as 'impact_negative' | 'impact_positive'
                )
              }
              className="input-select"
              required
            >
              <option value="impact_negative">Negative</option>
              <option value="impact_positive">Positive</option>
            </select>
          </div>
          <div className="form-control">
            <label className="form-label">Description</label>
            <TextEditor
              value={description}
              onChange={setDescription}
              editorType="entity"
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <div className="flex justify-end space-x-2 mt-4">
            <button
              type="button"
              className="btn-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
            <button type="submit" className="btn-primary">
              Add Impact
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddImpact
