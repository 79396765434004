import React, { useEffect, useState } from 'react'
import { useUser } from 'util/UserContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import UserSettingsAccount from './UserSettingsAccount'
import UserSettingsProfile from './UserSettingsProfile'
import { Tabs, Tab, TabPanel, Container } from '@mui/joy'
import ScrollableTabList from 'ui/ScrollableTabList'
import { setDocumentTitle } from 'util/pageTitles'
import { capitalizeFirstLetter } from 'util/textUtils'

const UserSettings = () => {
    const { currentUser, awaitingUser } = useUser();
    const navigate = useNavigate()
    const { tab } = useParams<{ tab: string }>()
    const [selectedTab, setSelectedTab] = useState<string>(tab || 'account')

    useEffect(() => {
        setDocumentTitle(`Settings - ${capitalizeFirstLetter(selectedTab)}`);
    }, [selectedTab]);

    useEffect(() => {
        if (!awaitingUser && !currentUser) {
            navigate('/login', { replace: true })
        }
    }, [currentUser, awaitingUser, navigate])

    const handleTabChange = (
        event: React.SyntheticEvent | null,
        newValue: string | number | null
    ) => {
        if (typeof newValue === 'string') {
            setSelectedTab(newValue)
        }
    }

    return (
        <Container>
            <h1 className='text-2xl font-bold py-4'> Settings </h1>
            <Tabs
                value={selectedTab}
                defaultValue={tab || 'account'}
                onChange={handleTabChange}
            >
                <ScrollableTabList
                    disableUnderline={true}
                    sticky="bottom"
                >
                    <Tab
                        component={Link}
                        to={`/settings/account`}
                        value="account"
                        disableIndicator={true}
                    >
                        Account
                    </Tab>
                    <Tab
                        component={Link}
                        to={`/settings/profile`}
                        value="profile"
                        disableIndicator={true}
                    >
                        Profile
                    </Tab>
                </ScrollableTabList>
                <TabPanel value="account">
                    <UserSettingsAccount />
                </TabPanel>
                <TabPanel value="profile">
                    <UserSettingsProfile />
                </TabPanel>
            </Tabs>
        </Container>
    )
}

export default UserSettings
