import { useEffect, useState } from 'react'
import api from 'config/axiosConfig'
import { useParams, useSearchParams } from 'react-router-dom'
import ImpactCard from '../ImpactCard'
import { Loader } from 'lucide-react'
import ImpactProfileTabs from './ImpactProfileTabs'
import ImpactEditor from './ImpactEditor'
import { Impact, Scope } from 'util/constants'
import { setDocumentTitle } from 'util/pageTitles'

const ImpactProfile = () => {
  const { id } = useParams<{ id: string }>()
  const [impact, setImpact] = useState<Impact | null>(null)
  const [error, setError] = useState<string>('')
  const [editMode, setEditMode] = useState<boolean>(false)
  const [description, setDescription] = useState<string>('')
  const [scope, setScope] = useState<Scope>(null)
  const [referenceLink, setReferenceLink] = useState<string>('')
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setDocumentTitle(`Impact - ${impact?.title}`)
  }, [impact?.title])

  useEffect(() => {
    const fetchImpact = async () => {
      try {
        const response = await api.get<Impact>(`/api/impacts/${id}`)
        setImpact(response.data)
        setDescription(response.data.description)
        setScope(response.data.scope as Scope)
        setReferenceLink(response.data.reference_link)

        if (searchParams.get('edit') === 'true') {
          setEditMode(true)
        }
      } catch (err) {
        setError('Failed to load impact profile. Please try again later.')
      }
    }

    fetchImpact()
  }, [id, searchParams])

  if (error) {
    return <div className="error-message">{error}</div>
  }

  if (!impact) {
    return (
      <div className="flex justify-center items-center my-4">
        <Loader className="animate-spin h-12 w-12 text-eoe-gray dark:text-white" />
      </div>
    )
  }

  return (
    <div className="grid grid-rows-auto grid-flow-row gap-2">
      <div>
        {impact && (
          <>
            {editMode ? (
              <ImpactEditor
                impact={impact}
                description={description}
                scope={scope as Scope}
                referenceLink={referenceLink}
                editMode={true}
              />
            ) : (
              <ImpactCard
                impact={impact}
                truncateDescription={true}
                isImpactProfilePage={true}
              />
            )}
          </>
        )}
      </div>
      <ImpactProfileTabs impact={impact} />
    </div>
  )
}

export default ImpactProfile
