import React, { useEffect, useState } from 'react'
import { useUser } from 'util/UserContext'
import { Loader } from 'lucide-react'
import api from 'config/axiosConfig'
import CreateSolution from './CreateSolution'
import SolutionCard from './SolutionCard'
import AddButton from 'ui/AddButton'

import { Enemy, Solution } from 'util/constants'

interface Props {
  enemy: Enemy
}

interface SolutionResponse {
  id: number
  enemy_id: number
  solution_id: number
  created_ts: string
  updated_ts: string
  user_id: number
  upvotes: number
  downvotes: number
}

const SolutionsSection = ({ enemy }: Props) => {
  const { currentUser, setShowAuthModal } = useUser()
  const [solutions, setSolutions] = useState<Solution[]>([])
  const [error, setError] = useState('')
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    const fetchSolutions = async () => {
      try {
        const response = await api.get(`/api/solutions/enemy/${enemy.id}`)
        const solutionIds = response.data.map(
          (solution: SolutionResponse) => solution.solution_id
        )

        if (solutionIds.length === 0) {
          setSolutions([])
          return
        }

        const solutionsResponse = await api.get(
          `/api/solutions?solutionIds=${JSON.stringify(solutionIds)}`
        )
        setSolutions(solutionsResponse.data)
      } catch (err) {
        setError('Failed to load solutions. Please try again later.')
      }
    }

    if (enemy) {
      fetchSolutions()
    }
  }, [enemy])

  const handleSolutionAdded = async () => {
    try {
      const response = await api.get(`/api/solutions/enemy/${enemy.id}`)
      const solutionIds = response.data.map(
        (solution: SolutionResponse) => solution.solution_id
      )

      if (solutionIds.length === 0) {
        setSolutions([])
        return
      }

      const solutionsResponse = await api.get(
        `/api/solutions?solutionIds=${JSON.stringify(solutionIds)}`
      )
      setSolutions(solutionsResponse.data)
    } catch (err) {
      setError('Failed to load solutions. Please try again later.')
    }
  }

  const handleOpenModal = () => {
    if (!currentUser) {
      setShowAuthModal(true)
    } else {
      setOpenModal(true)
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  if (error) {
    return <div className="error-message">{error}</div>
  }

  if (!enemy) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader className="animate-spin h-12 w-12 text-eoe-gray dark:text-white" />
      </div>
    )
  }

  if (!solutions) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader className="animate-spin h-12 w-12 text-eoe-gray dark:text-white" />
      </div>
    )
  }

  return (
    <div>
      <div className="flex mx-2 mb-1">
        <AddButton
          tooltipTitle="Add a solution"
          buttonText="Solution"
          onClick={handleOpenModal}
        />
      </div>
      {openModal && (
        <CreateSolution
          enemyId={enemy.id}
          onSolutionAdded={handleSolutionAdded}
          onClose={handleCloseModal}
        />
      )}
      <div className="entity-divider">
        {solutions.length === 0 ? (
          <p className="mt-2">No solutions exist for this enemy.</p>
        ) : (
          solutions.map((solution) => (
            <SolutionCard
              key={`${solution.id}`}
              solution={solution}
              truncateDescription={true}
            />
          ))
        )}
      </div>
    </div>
  )
}

export default SolutionsSection
