import { useParams } from 'react-router-dom'
import FeedbackList from './FeedbackList'
import { Sort } from 'util/constants'

const FeedbackPage = () => {
    const { sort } = useParams<{ sort: Sort }>()

    return (
        <div className="main-page container mx-auto md:px-4">
            <FeedbackList sort={sort} />
        </div>
    )
}

export default FeedbackPage
