import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { SuggestionProps } from '@tiptap/suggestion';
import { ReactRenderer } from '@tiptap/react';
import tippy, { Instance as TippyInstance } from 'tippy.js';
import api from 'config/axiosConfig';
import { PluginKey } from '@tiptap/pm/state';

interface HashtagItem {
  id?: string;
  tag: string;
}

interface SuggestionItemProps {
  items: HashtagItem[];
  command: (item: HashtagItem) => void;
}

interface SuggestionListHandle {
  onKeyDown: ({ event }: { event: KeyboardEvent }) => boolean;
}

const SuggestionList = forwardRef<SuggestionListHandle, SuggestionItemProps>(
  ({ items, command }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: { event: KeyboardEvent }) => {
        if (event.key === 'ArrowUp') {
          setSelectedIndex((prevIndex) => (prevIndex + items.length - 1) % items.length);
          event.preventDefault();
          return true;
        } else if (event.key === 'ArrowDown') {
          setSelectedIndex((prevIndex) => (prevIndex + 1) % items.length);
          event.preventDefault();
          return true;
        } else if (event.key === 'Enter') {
          selectItem(selectedIndex);
          event.preventDefault();
          return true;
        }
        return false;
      },
    }));

    useEffect(() => {
      setSelectedIndex(0);
    }, [items]);

    const selectItem = (index: number) => {
      const item = items[index];
      if (item) {
        command(item);
      }
    };

    return (
      <div className="bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto z-50">
        {items.map((item, index) => (
          <div
            key={item.id || item.tag} // Use tag as key if id is undefined
            className={`px-4 py-2 cursor-pointer ${
              index === selectedIndex ? 'bg-blue-100' : ''
            }`}
            onClick={() => selectItem(index)}
          >
            #{item.tag}
          </div>
        ))}
      </div>
    );
  }
);

const hashtagSuggestion = {
  char: '#',
  pluginKey: new PluginKey('hashtagSuggestion'),
  startOfLine: false,
  items: async ({ query }: { query: string }) => {
    if (!query) return [];
  
    // Validate query: only allow alphanumeric characters
    const isValid = /^[a-zA-Z0-9]+$/.test(query);
    if (!isValid) return [];
  
    try {
      const response = await api.get<HashtagItem[]>(`/api/tags/search?q=${query}`);
      const existingHashtags = response.data.filter(tag => /^[a-zA-Z0-9]+$/.test(tag.tag));
  
      // Include current query only if no existing hashtags are found
      if (existingHashtags.length === 0) {
        return [{ tag: query }];
      } else {
        return existingHashtags;
      }
    } catch (error) {
      console.error('Error fetching hashtags:', error);
  
      // Even if there's an error, allow creating a new hashtag if valid
      return isValid ? [{ tag: query }] : [];
    }
  },
  command: ({ editor, range, props }: any) => {
    const isValid = /^[a-zA-Z0-9]+$/.test(props.tag);
    if (!isValid) return;
    editor
      .chain()
      .focus()
      .insertContentAt(range, [
        {
          type: 'hashtag',
          attrs: {
            label: props.tag,
          },
        },
        { type: 'text', text: ' ' },
      ])
      .run();
  },
  render: () => {
    let component: ReactRenderer<SuggestionListHandle>;
    let popup: TippyInstance[];

    return {
      onStart: (props: SuggestionProps) => {
        component = new ReactRenderer(SuggestionList, {
          props: props as any,
          editor: props.editor,
        });

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect as any,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          placement: 'bottom-start',
        });
      },
      onUpdate(props: SuggestionProps) {
        component.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect as any,
        });
      },
      onKeyDown({ event }: { event: KeyboardEvent }) {
        if (event.key === 'Escape') {
          popup[0].hide();
          return true;
        }

        return component.ref?.onKeyDown({ event }) ?? false;
      },
      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};

export default hashtagSuggestion;
