import React from 'react'
import { useParams, NavLink } from 'react-router-dom'
import CommentsSection from 'components/Comments/CommentsSection'
import ImpactEnemy from './ImpactEnemy'
import { Impact } from 'util/constants'

interface Props {
  impact: Impact
}

const ImpactProfileTabs = ({ impact }: Props) => {
  const { id, tab } = useParams<{ id: string; tab?: string }>()
  const selectedTab = tab || 'comments'

  return (
    <div className="grid grid-rows-auto grid-flow-row gap-2">
      <div className="p-2">
        <nav className="flex space-x-4 overflow-x-auto whitespace-nowrap">
          <NavLink
            to={`/impact/${id}`}
            end
            className={({ isActive }) =>
              isActive || !tab ? 'tab-active' : 'tab-inactive'
            }
          >
            Comments
          </NavLink>
          <NavLink
            to={`/impact/${id}/enemy`}
            className={({ isActive }) =>
              isActive ? 'tab-active' : 'tab-inactive'
            }
          >
            Enemy
          </NavLink>
        </nav>
      </div>
      <div className="grid grid-rows-auto grid-flow-row gap-2">
        {(selectedTab === 'comments' || !tab) && (
          <CommentsSection
            commentable_id={impact.id}
            commentable_type="impact"
          />
        )}
        {selectedTab === 'enemy' && (
          <ImpactEnemy enemyId={impact.enemy_id} />
        )}
      </div>
    </div>
  )
}

export default ImpactProfileTabs
