import React, { useEffect, useRef, useState } from 'react'
import { useUser } from 'util/UserContext'
import Vote from './Vote'
import Save from './Save'
import { Menu, MenuItem } from '@mui/joy'
import './EntityActions.css'
import { Enemy, Impact, Solution, EntityType, Comment, Feedback } from 'util/constants'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import ShareIcon from '@mui/icons-material/Share'
import LinkIcon from '@mui/icons-material/Link'

interface Props {
    entity: Enemy | Solution | Impact | Comment | Feedback
    entityType: EntityType
    onVote?: () => void
    onSave?: () => void
}

const EntityActions = ({
    entity,
    entityType,
    onVote,
    onSave,
}: Props) => {
    const { currentUser } = useUser();
    const [copied, setCopied] = useState(false)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)
    const shareIconRef = useRef<HTMLButtonElement>(null)
    const menuRef = useRef<HTMLDivElement>(null)
    const entityId = entityType === 'comment' 
    ? (entity as Comment).id?.toString() 
    : entityType === 'enemy' 
        ? (entity as Enemy).name?.replace(/ /g, '_') 
        : (entity as Solution | Impact).id.toString()
    const url = `${window.location.origin}/${entityType}/${entityId}`

    const handleMenuToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (anchorEl) {
            setAnchorEl(null) // Close the menu if it's already open
        } else {
            setAnchorEl(event.currentTarget) // Open the menu
        }
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          const element = event.target as HTMLElement;
          if (
            anchorEl &&
            !shareIconRef.current?.contains(element) &&
            !menuRef.current?.contains(element)
          ) {
            handleMenuClose();
          }
        };
      
        document.addEventListener('mousedown', handleClickOutside);
      
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [anchorEl]);

    const handleShare = () => {
        navigator.clipboard.writeText(url).then(() => {
            handleMenuClose()
            setAnchorEl(null)
            setCopied(true)
            setTimeout(() => setCopied(false), 2000)
        })
    }

    const handleComment = () => {
        if (window.location.href === `${url}/comments`) {
            const commentsSection =
                document.getElementsByClassName('comment-title')[0]
            if (commentsSection) {
                commentsSection.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                })
            }
        } else {
            window.location.href = `${url}/comments`
        }
    }

    return (
        <div className={`${entityType !== 'comment' ? 'entity-actions flex items-center mt-2 md:mt-0 md:flex-col ' : 'entity-actions flex items-center mt-2 md:mt-0 md:flex-row'}`}>
            <Vote
                entityId={entity.id}
                onVote={onVote}
                voteCount={entity.vote_count}
                entityType={entityType}
            />
            <div className="action comment flex text-xl grow">
                {entityType !== 'comment' &&
                <button
                    className="size-9 rounded-full text-eoe-gray dark:text-eoe-dark-icon-stroke dark:hover:text-eoe-dark-icon-stroke-hover bg-gray-200 dark:bg-eoe-dark-icon flex justify-center items-center hover:bg-gray-300 dark:hover:bg-eoe-dark-icon-hover transition-colors"
                    onClick={handleComment}
                >
                    <ChatBubbleOutlineIcon />
                </button>
                }
            </div>
            {entityType !== 'comment' && (
                <>
            <div className="action save flex text-xl relative mx-2 md:my-2">
                <Save
                    entityId={entity.id.toString()}
                    entityType={entityType}
                    currentUser={currentUser}
                    onSave={onSave}
                />
            </div>

            <div className="action share text-xl relative">
                <button
                    className="size-9 rounded-full text-eoe-gray dark:text-eoe-dark-icon-stroke dark:hover:text-eoe-dark-icon-stroke-hover bg-gray-200 dark:bg-eoe-dark-icon flex justify-center items-center hover:bg-gray-300 dark:hover:bg-eoe-dark-icon-hover transition-colors"
                    onClick={handleMenuToggle}
                    ref={shareIconRef}
                >
                    <ShareIcon />
                </button>

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    ref={menuRef}
                    className="text-eoe-gray dark:text-eoe-dark-icon-stroke dark:hover:text-eoe-dark-icon-stroke-hover bg-gray-200 dark:bg-eoe-dark-icon flex justify-center items-center hover:bg-gray-300 dark:hover:bg-eoe-dark-icon-hover transition-colors"
                >
                    <MenuItem onClick={handleShare} className="text-eoe-gray dark:text-eoe-dark-icon-stroke dark:hover:text-eoe-dark-icon-stroke-hover bg-gray-200 dark:bg-eoe-dark-icon flex justify-center items-center hover:bg-gray-300 dark:hover:bg-eoe-dark-icon-hover transition-colors">
                        <LinkIcon className="text-eoe-gray dark:text-eoe-dark-icon-stroke dark:hover:text-eoe-dark-icon-stroke-hover bg-gray-200 dark:bg-eoe-dark-icon flex justify-center items-center hover:bg-gray-300 dark:hover:bg-eoe-dark-icon-hover transition-colors"/>
                    </MenuItem>
                </Menu>
                {copied && (
                    <div className="copied-banner text-sm absolute">
                        Copied!
                    </div>
                )}
            </div>
            </>
            )}
        </div>
    )
}

export default EntityActions
