import React, { useState, useEffect } from 'react'
import { useUser } from 'util/UserContext'
import { Box, Typography, CircularProgress, Grid, Button } from '@mui/joy'
import { Link, useSearchParams } from 'react-router-dom'
import api from 'config/axiosConfig'
import EnemyCard from 'components/Enemies/EnemyCard'
import ImpactCard from 'components/Impacts/ImpactCard'
import SolutionCard from 'components/Solutions/SolutionCard'
import FeedbackCard from 'components/Feedback/FeedbackCard'
import EntitiesSort from 'components/Entities/EntitiesSort'
import { SavedEntity, Sort } from 'util/constants'

const UserProfileSaved = () => {
    const { currentUser } = useUser()
    const [searchParams, setSearchParams] = useSearchParams()
    const [savedEntities, setSavedEntities] = useState<SavedEntity[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const [offset, setOffset] = useState(0)
    const [hasMore, setHasMore] = useState(true)
    const limit = 10

    const sort = (searchParams.get('sort') as Sort) || 'new'

    const fetchUserSavedEntities = async (reset = false) => {
        try {
            const response = await api.get(`/api/saves`, {
                params: {
                    sortField: getSortField(sort),
                    sortOrder: getSortOrder(sort),
                    offset: reset ? 0 : offset,
                    limit
                }
            })
            if (reset) {
                setSavedEntities(response.data)
                setOffset(limit)
            } else {
                setSavedEntities(prev => [...prev, ...response.data])
                setOffset(prev => prev + limit)
            }
            setHasMore(response.data.length === limit)
            setLoading(false)
        } catch (err) {
            setError('Failed to load saved entities')
            setLoading(false)
        }
    }

    useEffect(() => {
        if (currentUser?.username) {
            fetchUserSavedEntities(true)
        } else {
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser?.username, sort])

    const getSortField = (sortValue: Sort): 'saved_ts' | 'e.vote_count' => {
        switch (sortValue) {
            case 'top':
            case 'bottom':
                return 'e.vote_count'
            case 'new':
            case 'old':
                return 'saved_ts'
            default:
                return 'saved_ts'
        }
    }

    const getSortOrder = (sortValue: Sort): 'DESC' | 'ASC' => {
        switch (sortValue) {
            case 'top':
            case 'new':
                return 'DESC'
            case 'bottom':
            case 'old':
                return 'ASC'
            default:
                return 'DESC'
        }
    }

    const handleSortChange = (newSort: Sort) => {
        setSearchParams({ sort: newSort })
        setOffset(0)
        setSavedEntities([])
    }

    const loadMore = () => {
        fetchUserSavedEntities()
    }

    const renderEntityCard = (entity: SavedEntity) => {
        switch (entity.entity_type) {
            case 'enemy':
                return (
                    <EnemyCard
                        enemy={entity.attributes}
                        showMeta={true}
                        showActions={true}
                        truncateDescription={true}
                        isEnemyProfilePage={false}
                    />
                )
            case 'impact':
                return (
                    <ImpactCard
                        impact={entity.attributes}
                        showMeta={true}
                        truncateDescription={true}
                        isImpactProfilePage={false}
                    />
                )
            case 'solution':
                return (
                    <SolutionCard
                        solution={entity.attributes}
                        showMeta={true}
                        truncateDescription={true}
                        isSolutionProfilePage={false}
                    />
                )
            case 'feedback':
                return (
                    <FeedbackCard
                        feedback={entity.attributes}
                        showMeta={true}
                        truncateDescription={true}
                        isFeedbackProfilePage={false}
                    />
                )
            default:
                return null
        }
    }

    if (!currentUser?.username) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    py: 4,
                }}
            >
                <Typography level="h4" color="danger">
                    You do not have permission to access this page.
                </Typography>
                <Button
                    component={Link}
                    to={`/user/${currentUser?.username}/saved`}
                    variant="solid"
                    color="primary"
                >
                    View Your Saved Items
                </Button>
            </Box>
        )
    }

    if (loading) {
        return <CircularProgress />
    }

    if (error) {
        return <Typography color="danger">{error}</Typography>
    }

    return (
        <>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', py: .5, px: 2 }}>
                <EntitiesSort entityType="saved" sort={sort} onSortChange={handleSortChange} />
            </Box>
            <Grid container spacing={1}>
                <div className="flex flex-col gap-2 divide-y divide-gray-200">
                    {savedEntities.map((entity) => (
                        <Grid key={entity.eid}>
                            {renderEntityCard(entity)}
                        </Grid>
                    ))}
                </div>
            </Grid>
            {hasMore && (
                <Button onClick={loadMore} sx={{ mt: 2 }} disabled={loading}>
                    {loading ? 'Loading...' : 'Load More'}
                </Button>
            )}
        </>
    )
}

export default UserProfileSaved
