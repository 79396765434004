import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { TabList, TabListProps } from '@mui/joy';
import ScrollButton from './ScrollButton';
import TabLink from './TabLink';

const ScrollableContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-width: 0px;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
 '&::-webkit-scrollbar-thumb': { display: 'none' },
`;

const StyledTabList = styled(TabList)`
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  white-space: nowrap;
  border-width: 0px;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
 '&::-webkit-scrollbar-thumb': { display: 'none' },
`;


const ScrollableTabList: React.FC<TabListProps> = ({ children, ...props }) => {
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);
  const tabListRef = useRef<HTMLDivElement>(null);

  const checkForScrollButtons = () => {
    if (tabListRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabListRef.current;
      setShowLeftScroll(scrollLeft > 1); // Changed from 0 to 1 to account for potential rounding errors
      setShowRightScroll(Math.ceil(scrollLeft + clientWidth) < scrollWidth - 1); // Added Math.ceil and -1 to account for potential rounding errors
    }
  };

  useEffect(() => {
    const handleResize = () => {
      checkForScrollButtons();
    };

    checkForScrollButtons(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Check again after a short delay to account for any layout shifts
    const timer = setTimeout(() => {
      checkForScrollButtons();
    }, 100);

    return () => clearTimeout(timer);
  }, [children]); // Re-run when children change

  const handleScroll = () => {
    checkForScrollButtons();
  };

  const scroll = (direction: 'left' | 'right') => {
    if (tabListRef.current) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      tabListRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="dark:!bg-eoe-dark-bg">
    <ScrollableContainer>
      <ScrollButton
        direction="left"
        onClick={() => scroll('left')}
        visible={showLeftScroll}
      />
      <StyledTabList
        ref={tabListRef}
        onScroll={handleScroll}
        {...props}
      >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.type === TabLink) {
            return React.cloneElement(child as React.ReactElement<{ style?: React.CSSProperties }>, {
                style: { ...child.props.style, flex: 1 }
              });
        }
          return child;
        })}
      </StyledTabList>
      <ScrollButton
        direction="right"
        onClick={() => scroll('right')}
        visible={showRightScroll}
      />
      </ScrollableContainer>
    </div>
  );
};

export { TabLink };
export default ScrollableTabList;