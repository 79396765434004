import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
    relativeTime: {    
        future: "in %s",
        past: "%s ago",
        s: 'a few secs',
        m: "1 minute",
        mm: "%d minutes",
        h: "1 hour",
        hh: "%d hours",
        d: "1 day",
        dd: "%d days",
        M: "1 month",
        MM: "%d months",
        y: "a year",
        yy: "%d years"
    }
});

export const formatDate = (date: string | number | Date | dayjs.Dayjs) => {
    return dayjs(date).format('LLL');
};

export const formatRelativeTime = (date: string | number | Date | dayjs.Dayjs) => {
    return dayjs(date).fromNow();
};

export default dayjs;