import { useEffect } from 'react';
import { setDocumentTitle } from 'util/pageTitles';

function PrivacyPolicy() {
    useEffect(() => {
        setDocumentTitle('EoE - Privacy Policy');
    }, []);

    return (
      <div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
            Societea Privacy Policy
          </h1>
          <div className="mt-6 prose prose-lg dark:prose-invert text-gray-600 dark:text-gray-400">
            <p className="text-gray-600 dark:text-gray-400">
              Effective: November 4, 2024. Last Revised: November 4, 2024.
            </p>
  
            <h2>Introduction</h2>
            <p>
              This Privacy Policy explains how and why Societea, Inc. (“<strong>Societea</strong>,” “<strong>we</strong>,” “<strong>us</strong>,” or “<strong>our</strong>”) collects, uses, and shares information about you when you use our websites, mobile apps, widgets, APIs, emails, and other online products (such as <a href="https://enemiesofearth.com" className="no-underline hover:underline">Enemies of Earth</a>) and services (collectively, the “<strong>Services</strong>”), or when you otherwise interact with us or receive a communication from us.
            </p>
            <p>
              We want this privacy policy to empower you to make better choices about how you use the Services. We'd encourage you to read the whole policy, but if you don't, here is the TL;DR:
            </p>
  
            <ul>
              <li>
                <strong>Our Services are public.</strong> Our Services are largely public. For example, on Enemies of Earth anyone can see your profile, posts, and comments.
              </li>
              <li>
                <strong>We collect minimal information about you.</strong> We collect minimal information that can be used to identify you by default. If you want to just browse, you don't need an account. If you want to create an account to participate in the community, we don't require you to give us your real name. We don't track your precise location. You can share as much or as little about yourself as you want.
              </li>
              <li>
                <strong>We only use data to improve your experience.</strong> Any data we collect is used primarily to provide our Services, which are focused on allowing people to come together and improve society. We don't sell your personal data to third parties, and we never work with data brokers. We encourage you to use our public feedback mechanism to let us know how we can improve your experience, as other users can also vote on your feedback to raise awareness and prioritize improvements.
              </li>
              <li>
                <strong>All of our users get privacy rights—not just those in select countries.</strong> Privacy rights are for everyone. Anyone can request a copy of their data, account deletion, or information about our policies.
              </li>
              <li>
                <strong>If you have questions about how we use data, just ask.</strong> We've tried our best to make this as easy to understand as possible but sometimes privacy policies are still confusing. If you need help understanding this policy or anything about Societea or our Services, just <a href="mailto:contact@societea.io">ask</a>.
              </li>
            </ul>
  
            <h2>Our Services are Public</h2>
            <p>
              Much of the information on the Services is public and accessible to everyone, even without an account. By using the Services, you are directing us to share this information publicly and freely.
            </p>
            <p>
              When you submit content (e.g., a post, comment, or chat message) to a public part of the Services, any visitors to and users of our Services will be able to see that content, the username associated with the content, and the date and time you originally submitted the content. That content and information may also be available in search results on Internet search engines like Google or in responses provided by an AI chatbot like OpenAI’s ChatGPT. You should take the public nature of the Services into consideration before posting.
            </p>
            <p>
              Your Societea account has a profile page that is public. Your profile contains information about your activities on the Services, such as your username, prior posts and comments, profile display name, about section, avatar or profile image, and social links.
            </p>
            <p>
              We offer social sharing features that let you share content or actions you take on our Services with other media. Your use of these features enables the sharing of certain information with your friends or the public, depending on the settings you establish with the third party that provides the social sharing feature. For more information about the purpose and scope of data collection and processing in connection with social sharing features, please visit the privacy policies of the third parties that provide these social sharing features (e.g., <a href="https://privacycenter.instagram.com/policy/">Instagram</a>, <a href="https://www.tiktok.com/legal/page/us/privacy-policy/en">TikTok</a>, and <a href="https://x.com/en/privacy">X</a>).
            </p>
            <p>
              Please review our public content policy <a href="/policies/public-content-policy">here</a> for more information about how your public content is publicly available and accessible to anyone with access to the internet.
            </p>
  
            <h2>What Information We Collect</h2>
            <h3>Information You Provide to Us</h3>
            <p>
              We collect information you provide to us directly when you use the Services. This includes:
            </p>
  
            <h4>Account information</h4>
            <p>
              You don't need an account to use our Services. If you create a Societea account, your account will have a username for the Services, which you provide or which is automatically generated. Your username is public, and it doesn't have to be related to your real name. You may need to provide a password, depending on whether you register using an email address or Single Sign-On (SSO) feature (such as Google).
            </p>
            <p>
              When you use our Services, you may also provide other information, such as a bio, profile picture, or social link. This information is optional and may be removed at any time.
            </p>
            <p>
              We also store your user account preferences and settings. We may ask for such information prior to your creating a username or account to help improve your experience exploring the Services.
            </p>
  
            <h4>Non-Public Content You Submit</h4>
            <p>
              In addition to the public content you submit to the Services, we also collect non-public content. This includes your saved drafts of posts or comments, your messages with other users (such as private messages), and your reports and other communications with moderators and with us. Your content may include text, links, images, gifs, audio, videos, software, and tools.
            </p>
  
            <h4>Actions you take</h4>
            <p>
              We collect information about the actions you take when using the Services. This includes your interactions with the platform and content, like voting, commenting, saving, and reporting. It also includes your interactions with other users, such as following, and blocking. We collect your interactions with communities, like your subscriptions or moderator status.
            </p>
  
            <h4>Transactional information</h4>
            <p>
              If you purchase products or services from us or otherwise through the Services, you will have to provide payment information in order to complete your purchase. Societea uses industry-standard payment processor services (such as Stripe) to handle payment information, and those services are subject to separate terms and conditions and privacy policies. We will collect information about the product or service you are purchasing (for example, purchase dates, amounts paid or received, and expiration and renewal dates).
            </p>
  
            <h4>Other information</h4>
            <p>
              You may choose to provide other information directly to us. For example, we may collect information when you fill out a form, survey, participate in Societea-sponsored activities, promotions, or programs, apply for a job, request customer support, or otherwise communicate with us.
            </p>
  
            <h3>Information We Collect Automatically</h3>
            <p>
              When you access or use our Services, we may also automatically collect information about you. This includes:
            </p>
  
            <h4>Log and usage data</h4>
            <p>
              We may log information when you access and use the Services. This may include your IP address, user-agent string, browser type, operating system, referral URLs, device information (such as device IDs), device settings, mobile carrier name, pages visited, links clicked, the requested URL, and search terms. Except for the IP address used to create your account, Societea will delete any IP addresses collected after 100 days.
            </p>
  
            <h4>Information collected from cookies and similar technologies</h4>
            <p>
              We may receive information from cookies, which are pieces of data your browser stores and sends back to us when making requests, and similar technologies. We use this information to deliver and maintain our Services, improve your experience, understand user activity, personalize content, and improve the quality of our Services. For example, we store and retrieve information about your preferred language and other settings. See our <a href="/policies/cookie-notice">Cookie Notice</a> for more information about how Societea uses cookies. For more information on how you can disable cookies, please see “Your Choices” below.
            </p>
  
            <h4>Location information</h4>
            <p>
              We may receive and process information about your location. We may receive location information from you when you choose to share such information on our Services, or we may derive an approximate location based on your IP address.
            </p>
  
            <h3>Information Collected from Other Sources</h3>
            <p>
              We may receive information about you from other sources, including from other users and third parties, and combine that information with the other information we have about you. For example, we may receive demographic or interest information about you from third parties and combine it with data you have provided to Societea, using a common account identifier such as a hash of an email address or a mobile-device ID.
            </p>
  
            <h4>Linked services</h4>
            <p>
              If you authorize or link a third-party service, such as an unofficial mobile app client, to access your Societea account, Societea receives information about your use of that service when it uses that authorization. Linking services may also cause the other service to send us information about your account with that service. For example, if you sign in to Enemies of Earth with a third-party identity provider, that provider may share an email address with us. To learn how information is shared with linked services, see “How Information About You Is Shared” below.
            </p>
  
            <h4>Information collected from integrations</h4>
            <p>
              We also may receive information about you, including log and usage data and cookie information, from third-party sites that integrate our Services. You can control how we use this information to personalize the Services for you as described in “Your Choices - Controlling Analytics” below.
            </p>
  
            <h3>Information Collected by Third Parties</h3>
            <h4>Embedded content</h4>
            <p>
              Societea displays some linked content in-line on the Services via embeds. For example, Enemies of Earth posts that link to YouTube or X may load the linked video or tweet within the application directly from those services to your device. In general, Societea does not control how third-party services collect data when they serve you their content directly via these embeds. As a result, embedded content is not covered by this privacy policy but by the policies of the service from which the content is embedded.
            </p>
  
            <h3>Information Collected from Societea Program Participants and Potential Participants</h3>
            <p>
              If you sign up to participate in a Societea Program (for example, our Contributor Program), we collect, directly or indirectly through our providers, some additional information from you (such as your name, date of birth, address, email, tax, government ID, and payment information). This additional information is used and shared with our third-party payment and compliance providers to determine your eligibility to participate in the applicable Societea Program, facilitate payments, and comply with law. Those providers and their services are subject to separate terms and conditions and privacy policies.
            </p>
  
            <h2>How We Use Information</h2>
            <p>We use information about you to:</p>
            <ul>
              <li>Provide, maintain, and improve the Services;</li>
              <li>Personalize services, content, and features that match your activities, preferences, and settings;</li>
              <li>
                Help protect the safety of Societea and our users, which includes blocking suspected spammers, addressing abuse, and enforcing the Societea User Agreement and our other terms and policies, as well as comply with applicable law;
              </li>
              <li>Research and develop new services;</li>
              <li>Send you technical notices, updates, security alerts, invoices, and other support and administrative messages;</li>
              <li>Provide customer service;</li>
              <li>
                Communicate with you about products, services, offers, promotions, events, and programs, and provide other news and information we think will be of interest to you (for information about how to opt out of these communications, see “Your Choices” below); and
              </li>
              <li>Monitor and analyze trends, usage, and activities in connection with our Services.</li>
            </ul>
  
            <h2>How We Share Information</h2>
            <p>
              In addition to the content that is displayed publicly as described above, we may share information in the following ways:
            </p>
            <ul>
              <li>
                <strong>With your consent.</strong> We may share information about you with your consent or at your direction. For example, at your direction through social sharing features that allow you to share content with third party sites like Instagram and X.
              </li>
              <li>
                <strong>With linked services.</strong> If you link your Societea account with a third-party service, Societea will share the information you authorize with that third-party service. You can control this sharing as described in "Your Rights and Choices" below.
              </li>
              <li>
                <strong>With our service providers.</strong> We may share information with vendors, consultants, and other service providers who need access to such information to carry out work for us. Their use of personal data will be subject to appropriate confidentiality and security measures. A few examples: (i) payment processors who process transactions on our behalf, (ii) cloud providers who host our data and our services, and (iii) compliance providers who help us determine your eligibility to participate in Societea Programs.
              </li>
              <li>
                <strong>To comply with the law.</strong> We may share information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, legal process, or governmental request, including, but not limited to, meeting national security or law enforcement requirements. To the extent the law allows it, we will attempt to provide you with prior notice before disclosing your information in response to such a request.
              </li>
              <li>
                <strong>In an emergency.</strong> We may share information if we believe it's necessary to prevent imminent and serious bodily harm to a person.
              </li>
              <li>
                <strong>To enforce our rights and promote safety and security.</strong> We may share information if we believe your actions are inconsistent with our User Agreement, rules, or other Societea terms and policies, or to protect the rights, property, and safety of the Services, ourselves, and others.
              </li>
              <li>
                <strong>With our affiliates.</strong> We may share information between and among Societea, and any of our parents, affiliates, subsidiaries, and other companies under common control and ownership.
              </li>
              <li>
                <strong>Aggregated or de-identified information.</strong> We may share information about you that has been aggregated or anonymized such that it cannot reasonably be used to identify you. For example, we may show the total number of times a post has been upvoted without identifying who the visitors were.
              </li>
            </ul>
  
            <h2>How We Protect Your Information</h2>
            <p>
              We take measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction. For example, we use HTTPS while information is being transmitted. We also enforce technical and administrative access controls to limit which of our employees have access to nonpublic personal information.
            </p>
            <p>
              We store the information we collect for as long as it is necessary for the purpose(s) for which we originally collected it. We may retain certain information for legitimate business purposes and/or if we believe doing so is in accordance with, or as required by, any applicable law. For example, if you violate our policies and your account is suspended or banned, we may store the identifiers used to create the account (such as email address) to prevent you from creating new accounts.
            </p>
  
            <h2>Your Rights and Choices</h2>
            <p>
              You have choices about how to protect and limit the collection, use, and sharing of information about you when you use the Services. Depending on where you live, you may also have the right to correction/rectification of your personal information, or to withdraw consent for processing where you have previously provided consent. Below we explain how to exercise each of these rights. Societea does not discriminate against users for exercising their rights under data protection laws.
            </p>
  
            <h3>Accessing and Changing Your Information</h3>
            <p>
              You can access your information and change or correct certain information through the Services. You can also request a copy of the personal information Societea maintains about you by following the process described <a href="/help/data-request">here</a>.
            </p>
  
            <h3>Deleting Your Account</h3>
            <p>
              You may delete your account information at any time from the user preferences page. When you delete your account, your profile is no longer visible to other users and disassociated from content you posted under that account. Please note, however, that the posts, comments, and messages you submitted prior to deleting your account will still be visible to others unless you first delete the specific content. After you submit a request to delete your account, our purge script commences the deletion process within 90 days. After running our purge script, Societea will not be able to provide access to deleted data. We may also retain certain information about you for legitimate business purposes and/or if we believe doing so is in accordance with, or as required by, any applicable law.
            </p>
  
            <h3>Controlling Linked Services' Access to Your Account</h3>
            <p>
              You can review the services you have permitted to access your account and revoke access to individual services by visiting your account’s Apps page (for third-party app authorizations), the Connected Accounts section of your Account Settings (for Google Sign-In), and the Social links section of your Profile Settings.
            </p>
  
            <h3>Controlling the Use of Cookies</h3>
            <p>
              Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject first- and third-party cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Services. For more information on controlling how cookies and similar technologies are used on Societea, see our <a href="/policies/cookie-notice">Cookie Notice</a>.
            </p>
  
            <h3>Controlling Analytics</h3>
            <p>
              Some analytics providers we partner with may provide specific opt-out mechanisms and we may provide, as needed and as available, additional tools and third-party services that allow you to better understand cookies and how you can opt out. For example, you may manage the use and collection of certain information by Google Analytics via the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Browser Add-on</a>.
            </p>
  
            <h3>Do Not Track</h3>
            <p>
              Most modern web browsers give you the option to send a Do Not Track signal to the sites you visit, indicating that you do not wish to be tracked. However, there is no accepted standard for how a site should respond to this signal, and we do not take any action in response to this signal. Instead, in addition to publicly available third-party tools, we offer you the choices described in this policy to manage the collection and use of information about you.
            </p>
  
            <h3>Controlling Promotional Communications</h3>
            <p>
              You may opt out of receiving some or all categories of promotional communications from us by following the instructions in those communications or by updating your email options in your account preferences. If you opt out of promotional communications, we may still send you non-promotional communications, such as information about your account or your use of the Services.
            </p>
  
            <h3>Controlling Mobile Notifications</h3>
            <p>
              With your consent, we may send promotional and non-promotional push notifications or alerts to your mobile device. You can deactivate these messages at any time by changing the notification settings on your mobile device.
            </p>
  
            <h3>Controlling Location Information</h3>
            <p>
              You can control how we use location information for recommendations customization via the Location Customization setting in your Account Settings.
            </p>
  
            <p>
              If you have questions or are not able to submit a request to exercise your rights using the mechanisms above, you may also email us at <a href="mailto:mydata@societea.io">mydata@societea.io</a> from the email address that you have verified with your Societea account.
            </p>
            <p>
              Before we process a request from you about your personal information, we need to verify the request via your access to your Societea account or to a verified email address associated with your Societea account. If we deny your request, you may appeal our decision by contacting us at <a href="mailto:mydata@societea.io">mydata@societea.io</a>.
            </p>
  
            <h2>International Data Transfers</h2>
            <p>
              Societea, Inc. is based in the United States and we process and store information on servers located in the United States. We may store information on servers and equipment in other countries depending on a variety of factors, including the locations of our users and service providers. By accessing or using the Services or otherwise providing information to us, you consent to the processing, transfer, and storage of information in and to the United States and other countries, where you may not have the same rights as you do under local law.
            </p>
            <p>
              When we transfer the personal data of users in the EEA, UK and/or Switzerland, we rely on the Standard Contractual Clauses approved by the European Commission for such transfers or other transfer mechanisms deemed ‘adequate’ under applicable laws.
            </p>
  
            <h3>EU-U.S., UK Extension, and Swiss-U.S. Data Privacy Frameworks Disclosure</h3>
            <p>
              If there is any conflict between the terms in this privacy policy and the EU-U.S. DPF Principles and/or the Swiss-U.S. DPF Principles, the Principles shall govern. To learn more about the Data Privacy Framework (DPF) Program, visit <a href="https://www.dataprivacyframework.gov/">https://www.dataprivacyframework.gov/</a>.
            </p>
  
            <h2>Additional Information for EEA and UK Users</h2>
            <p>
              Users in the EEA and UK have the right to request access to, rectification of, or erasure of their personal data; to data portability in certain circumstances; to request restriction of processing; to object to processing; and to withdraw consent for processing where they have previously provided consent. These rights can be exercised as described in the “Your Rights and Choices" section above. EEA users also have the right to lodge a complaint with their local supervisory authority.
            </p>
            <p>
              As required by applicable law, we collect and process information about individuals in the EEA and UK only where we have a legal basis for doing so. Our legal bases depend on the Services you use and how you use them. We process your information where:
            </p>
            <ul>
              <li>
                It is necessary to fulfill our contract with you, including to provide, operate, and improve the Services, provide customer support, personalize features and to protect the safety and security of the Services;
              </li>
              <li>
                It satisfies a legitimate interest (which is not overridden by your data protection interests), such as preventing fraud, ensuring network and information security, enforcing our rules and policies, protecting our legal rights and interests, research and development, personalizing the Services, and marketing and promoting the Services;
              </li>
              <li>You have consented for us to do so for a specific purpose; or</li>
              <li>We need to process your information to comply with our legal obligations.</li>
            </ul>
  
            <h2>Additional Information for California Users</h2>
            <p>
              The California Consumer Privacy Act (“<strong>CCPA</strong>”), as amended, requires us to provide California residents with some additional information, which we address in this section.
            </p>
            <p>
              In the last 12 months, we collected the following categories of personal information from California residents, depending on the Services used:
            </p>
            <ul>
              <li>
                Identifiers and account information, like your Enemies of Earth username, email address, IP address, and cookie information.
              </li>
              <li>
                Commercial information, including information about transactions you undertake with us.
              </li>
              <li>
                Internet or other electronic network activity information, such as information about your activity on our Services.
              </li>
              <li>Geolocation information based on your IP address.</li>
              <li>Your messages with other users (for example, private messages).</li>
              <li>
                Audiovisual information in pictures, audio, or video content submitted to the Services.
              </li>
              <li>
                Professional or employment-related information or demographic information, but only if you explicitly provide it to us, such as by applying for a job or filling out a survey.
              </li>
              <li>
                Inferences we make based on other collected data, for purposes such as recommending content and analytics.
              </li>
            </ul>
            <p>
              You can find more information about (a) what we collect and sources of that information in the “Information We Collect” section of this notice, (b) the business and commercial purposes for collecting that information in the “How We Use Information” section, and (c) the categories of third parties with whom we share that information in the “How We Share Information” section.
            </p>
            <p>
              If you are a California resident, you have additional rights under the CCPA, including the right to opt out of any sales or sharing of your personal information, to request access to and information about our data practices, and to request deletion or correction of your personal information, as well as the right not to be discriminated against for exercising your privacy rights. Societea does not “sell” or “share” personal information as those terms are defined under the CCPA. We do not use or disclose sensitive personal information except to provide you the Services or as otherwise permitted by the CCPA.
            </p>
            <p>
              You may exercise your rights to access, delete, or correct your personal information as described in the “Your Rights and Choices” section of this notice. When you make a request, we will verify your identity by asking you to sign into your account or if necessary by requesting additional information from you. You may also make a rights request using an authorized agent. If you submit a rights request from an authorized agent who does not provide a valid power of attorney, we may ask the authorized agent to provide proof that you gave the agent signed permission to submit the request to exercise rights on your behalf. In the absence of a valid power of attorney, we may also require you to verify your own identity directly with us or confirm to us that you otherwise provided the authorized agent permission to submit the request. If you have any questions or concerns, you may reach us using the methods described under “Your Rights and Choices” or by emailing us at <a href="mailto:mydata@societea.io">mydata@societea.io</a>.
            </p>
  
            <h2>Children</h2>
            <p>
              Children under the age of 13 are not allowed to create an account or otherwise use the Services. Additionally, if you are located outside the United States, you must be over the age required by the laws of your country to create an account or otherwise use the Services, or we need to have obtained verifiable consent from your parent or legal guardian.
            </p>
  
            <h2>Changes to This Policy</h2>
            <p>
              We may change this Privacy Policy from time to time. If we do, we will let you know by revising the date at the top of the policy. If the changes, in our sole discretion, are material, we may also notify you by sending an email to the address associated with your account (if you have chosen to provide an email address) or by otherwise providing notice through our Services. We encourage you to review the Privacy Policy regularly to stay informed about our information practices and the ways you can help protect your privacy. By continuing to use our Services after Privacy Policy changes go into effect, you agree to be bound by the revised policy.
            </p>
  
            <h2>Contact Us</h2>
            <p>
              If you have other questions about this Privacy Policy, please contact us at:
            </p>
            <address>
              Societea, Inc.
              <br />
              1111B S Governors Ave, STE 23886
              <br />
              Dover, DE 19904
              <br />
              United States
            </address>
            <p>
              For email inquiries, you may reach us at{' '}
              <a href="mailto:data@societea.io">data@societea.io</a>.
            </p>
          </div>
        </div>
      </div>
    );
  }
  
  export default PrivacyPolicy;
  