// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Light mode styles */
.vote-icon path {
    stroke: #555555; /* eoe-gray */
    transition: stroke 0.2s, fill 0.2s;
}

/* Dark mode styles */
:root[class~="dark"] .vote-icon path {
    stroke: #DBE4E9; /* eoe-dark-icon-stroke */
}

/* Hover states */
.vote-button:hover .vote-icon path {
    stroke: #cf1f1f;
}

.vote-button.downvote:hover .vote-icon path {
    stroke: #1e40af;
}

/* Active states */
.vote-button.active .vote-icon path {
    fill: #cf1f1f;
    stroke: #cf1f1f;
}

.vote-button.downvote.active .vote-icon path {
    fill: #1e40af;
    stroke: #1e40af;
}

/* Dark mode hover */
:root[class~="dark"] .vote-button:hover .vote-icon path {
    stroke: #cf1f1f;
}

:root[class~="dark"] .vote-button.downvote:hover .vote-icon path {
    stroke: #1e40af;
}`, "",{"version":3,"sources":["webpack://./src/components/Entities/Vote.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,eAAe,EAAE,aAAa;IAC9B,kCAAkC;AACtC;;AAEA,qBAAqB;AACrB;IACI,eAAe,EAAE,yBAAyB;AAC9C;;AAEA,iBAAiB;AACjB;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA,kBAAkB;AAClB;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA,oBAAoB;AACpB;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["/* Light mode styles */\r\n.vote-icon path {\r\n    stroke: #555555; /* eoe-gray */\r\n    transition: stroke 0.2s, fill 0.2s;\r\n}\r\n\r\n/* Dark mode styles */\r\n:root[class~=\"dark\"] .vote-icon path {\r\n    stroke: #DBE4E9; /* eoe-dark-icon-stroke */\r\n}\r\n\r\n/* Hover states */\r\n.vote-button:hover .vote-icon path {\r\n    stroke: #cf1f1f;\r\n}\r\n\r\n.vote-button.downvote:hover .vote-icon path {\r\n    stroke: #1e40af;\r\n}\r\n\r\n/* Active states */\r\n.vote-button.active .vote-icon path {\r\n    fill: #cf1f1f;\r\n    stroke: #cf1f1f;\r\n}\r\n\r\n.vote-button.downvote.active .vote-icon path {\r\n    fill: #1e40af;\r\n    stroke: #1e40af;\r\n}\r\n\r\n/* Dark mode hover */\r\n:root[class~=\"dark\"] .vote-button:hover .vote-icon path {\r\n    stroke: #cf1f1f;\r\n}\r\n\r\n:root[class~=\"dark\"] .vote-button.downvote:hover .vote-icon path {\r\n    stroke: #1e40af;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
